import React, { useState, useEffect } from 'react';
import { Grid, List, ListItem } from '@material-ui/core';
import { imageBasePath, goalFrequencies, checkinUnits } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import { ProgressBar, CustomLink } from '../../../shared_ui_components';
import { CustomNumberFormatter } from '../../../utils';

export default function UserOverviewInitial(props) {
  const { chekcinOpenSet, currentCheckin } = props;
  const [orgGoals, orgGoalsSet] = useState({});
  const [salesGoals, salesGoalsSet] = useState({});

  useEffect(() => {
    if (currentCheckin && currentCheckin.length) {
      orgGoalsSet(currentCheckin.find((cat) => cat.is_organisation));
      salesGoalsSet(currentCheckin.find((cat) => !cat.is_organisation));
    }
  }, [currentCheckin]);

  return (
    <>
      <section className={styles.userBannerSection}>
        <Grid
          container
          direction="row"
          spacing={2}
          justify="center"
          className={styles.userBannerRow}
        >
          <Grid item xs={5} className={styles.userBannerWrap}>
            <h2 className={styles.userBannerHeading + ' heading2 '}>Hey Welcome,</h2>
            <p className={styles.userBannerParagraph + ' paragraph '}>
              It's your first time here! Check out organizational and sales activity to keep your
              <span className={styles.userBannerHighlight}> company on track</span>
            </p>
            <p className={styles.userBannerSubText + ' heading5 '}> Good luck, you can do it</p>
            <CustomLink
              className={styles.userBannerBtn + ' primaryBtn '}
              linkimg={'goal_icon_white.svg'}
              linktext="Start your first check-in"
              onClick={() => chekcinOpenSet(true)}
            />
            {/* <CheckinDrawer /> */}
          </Grid>
        </Grid>
      </section>
      <section className={styles.userOverviewSection}>
        <div className={styles.userOverviewWrap}>
          <Grid container direction="row" spacing={2} className={styles.userOverviewRow}>
            <Grid item xs={6}>
              <img src={imageBasePath + 'org_goal_blue.svg'} className={styles.userOverviewIcon} />
              <h3 className={styles.userOverviewHeading + ' heading3 '}>{orgGoals.name}</h3>
              <p className={styles.userOverviewDuration + ' supportText '}>
                {goalFrequencies.find((goal) => goal.id === orgGoals.frequency)?.label}
              </p>
              <p className={styles.userOverviewParagraph + ' paragraph '}>{orgGoals.description}</p>
              <div className={styles.userOverviewInnerRow}>
                <Grid container direction="row" spacing={2}>
                  {orgGoals.goals?.map((goal, key) => (
                    <Grid key={key} item xs={6} className="flexNotCentered">
                      <div className={styles.userOverviewGoalsBlk}>
                        <img
                          src={
                            imageBasePath +
                            checkinUnits.find((unit) => unit.id === goal.unit)?.imgBlue
                          }
                          className={styles.userOverviewGoalBlkIcon}
                        />
                        <p className={styles.userOverViewGoalsBlkHeading + ' heading6 '}>
                          {goal.name}
                        </p>
                        <p className={styles.userOverviewGoalsCount + ' heading4 '}>
                          {goal.expected_target && goal.unit == 2
                            ? `${CustomNumberFormatter(goal.expected_target)}`
                            : CustomNumberFormatter(goal.expected_target)}
                        </p>
                        <p className={styles.userOverviewGoalTarget + ' supportText '}>
                          <img
                            className={styles.goalTargetIcon}
                            src={imageBasePath + 'goal.svg'}
                            alt="image"
                          />
                          Goal Target
                        </p>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={6}>
              <img
                src={imageBasePath + 'sales_goal_green.svg'}
                className={styles.userOverviewIcon}
              />
              <h3 className={styles.userOverviewHeading + ' heading3 '}>{salesGoals.name}</h3>
              <p className={styles.userOverviewDuration + ' supportText '}>
                {goalFrequencies.find((goal) => goal.id === salesGoals.frequency)?.label}
              </p>
              <p className={styles.userOverviewParagraph + ' paragraph '}>
                {salesGoals.description}
              </p>
              {salesGoals.goals?.map((goal, key) => (
                <div key={key} className={styles.userOverviewSalesRow}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={6} className="flexNotCentered">
                      <div className={styles.userSalesLeadBlk}>
                        <img
                          className={styles.salesGoalLeadIcon}
                          src={
                            imageBasePath +
                            checkinUnits.find((unit) => unit.id === goal.unit)?.imgGreen
                          }
                          alt="image"
                        />
                        <p className={styles.salesLeadBlkText + ' heading6 '}>{goal.name}</p>
                      </div>
                    </Grid>
                    <Grid item xs={6} className="flexNotCentered">
                      <div className={styles.userSalesLeadBlkRight}>
                        <div className={styles.userSalesLeadBlkRightContent}>
                          <p className={styles.salesLeadBlkRightCount + ' heading4 '}>
                            {' '}
                            {CustomNumberFormatter(goal.expected_target)}
                          </p>
                          <p className={styles.salesLeadBlkRightText + ' supportText '}>
                            <img
                              className={styles.salesGoalTargetIcon}
                              src={imageBasePath + 'goal.svg'}
                              alt="image"
                            />
                            Goal Target
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
}
