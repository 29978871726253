import React, { useMemo } from 'react';
import styles from './MyTeam.module.scss';
import Organization from 'infrastructure/Organization';
import MyTeamTable from './MyTeamTable';
import { useGetRoleplayTeamQuery } from 'features/roleplay/Roleplay.queries';

const MyTeam = () => {
  const { data, isLoading } = useGetRoleplayTeamQuery();

  const isRep = useMemo(() => Organization.isRep(), []);
  if (isRep) return null;

  return (
    <div className={styles.container}>
      <MyTeamTable data={data} loading={isLoading} />
    </div>
  );
};

export default MyTeam;
