import React, { useEffect, useMemo, useState } from 'react';
import styles from './MyTeam.module.scss';
import MyTeamTableRow from './MyTeamTableRow';
import { InputSelect } from 'shared_ui_components';
import { FeatureFlag } from 'controllers/_exports';

const ACTIVE = 'active';
const UNASSIGNED = 'unassigned';
const INITIAL_OPTION = { label: 'Active', value: ACTIVE };

const MyTeamTable = ({ data, loading }) => {
  const [filter, setFilter] = useState(INITIAL_OPTION);
  const onFilterChange = (opt) => setFilter(opt);

  const options = useMemo(() => {
    if (!FeatureFlag.enabled('UNASSIGN_ROLEPLAY')) return [];
    const opts = [INITIAL_OPTION];
    if ((data || []).some((roleplay) => 'assigned' in roleplay && !Boolean(roleplay.assigned)))
      opts.push({ label: 'Unassigned', value: UNASSIGNED });
    return opts;
  }, [data]);

  useEffect(() => {
    // Verify filter still exists to avoid showing empty table
    if (!FeatureFlag.enabled('UNASSIGN_ROLEPLAY')) return;
    if (!options?.find((o) => o.value === filter?.value)) setFilter(options[0] || {});
  }, [options, filter.value]);

  const filteredData = useMemo(() => {
    if (data?.length <= 0) return [];
    if (!FeatureFlag.enabled('UNASSIGN_ROLEPLAY')) return data;
    if (filter?.value === UNASSIGNED)
      return data?.filter((roleplay) => !Boolean(roleplay?.assigned));
    return data?.filter((roleplay) => Boolean(roleplay?.assigned));
  }, [filter, data]);

  if (loading) return <p className={styles.message}>Loading...</p>;
  if (data?.length <= 0)
    return (
      <>
        <MyTeamTableRow isHeader />
        <p className={styles.message}>Your team activities will appear here.</p>
      </>
    );

  return (
    <div className={styles.myTeam}>
      <div className={styles.title}>
        <h1>My Team</h1>
        {options?.length > 1 && (
          <InputSelect
            className="reactSelect"
            closeMenuOnSelect
            options={options}
            onChange={onFilterChange}
            isSearchable={false}
            isClearable={false}
            value={filter}
          />
        )}
      </div>
      <div className={styles.table}>
        <MyTeamTableRow isHeader />
        {filteredData?.map((roleplay) => (
          <MyTeamTableRow key={roleplay?.uuid} roleplayData={roleplay} />
        ))}
      </div>
    </div>
  );
};

export default MyTeamTable;
