import { Component } from 'react';
import styles from '../../../assets/styles/training.module.scss';
import { AdminTrainingContainer, UserTrainingContainer } from '../components';

export default class TrainingAssesment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props.role?.slug,
    };
  }
  render() {
    return (
      <div className={styles.trainingPage}>
        {['primary_admin', 'admin', 'sales_lead'].includes(this.state.role) ? (
          <AdminTrainingContainer role={this.state.role} />
        ) : (
          <UserTrainingContainer role={this.state.role} />
        )}
      </div>
    );
  }
}
