import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath, goalFrequencies } from '../../../constants';
import styles from '../../../assets/styles/tracking.module.scss';
import {
  getOrganisationGoalsOverviewApi,
  getSalesGoalsOverviewApi,
  getTopPerformersApi,
  getBottomPerformersApi,
} from '../apiServices';
import {
  OverviewTitle,
  OrganisationGoalsView,
  AdminSalesGoalsView,
  SalesPerformers,
  ActivityHeatMap,
} from '.';
import { downloadReport } from '../apiServices';
import { downloadFileType } from '../../../utils';
import { CustomTargetValue } from '../../../shared_ui_components';
import Shimmer from './Shimmer';
import HubspotTrackingOverview from 'features/hubspot/components/HubspotTrackingOverview/HubspotTrackingOverview';
import RefreshHubspotData from 'features/hubspot/components/RefreshHubspotData';
import { useGetHubspotTrackingQuery } from 'features/hubspot/Hubspot.queries';
import { FeatureFlag } from 'controllers/_exports';

export default function AdminOverview(props) {
  const [downloadReportLoader, downloadReportLoaderSet] = useState(false);
  const [organisationOverview, setOrganisationOverview] = useState('');
  const [salesOverview, setSalesOverview] = useState('');
  const [topPerformers, setTopPerformers] = useState([]);
  const [bottomPerformers, setBottomPerformers] = useState([]);
  const [loader, setLoader] = useState({
    organisation: true,
    sales: true,
  });
  const [organisationAppliedFilters, setOrganisationAppliedFilters] = useState({});
  const [salesAppliedFilters, setSalesAppliedFilters] = useState({});
  const [organisationLoader, setOrganisationLoader] = useState(false);
  const [salesLoader, setSalesLoader] = useState(false);

  const fetchOrganisationGoalsOverview = (query = {}) => {
    if (FeatureFlag.enabled('REMOVE_SALES_GOALS')) return;
    setOrganisationLoader(true);
    setOrganisationAppliedFilters(query);
    getOrganisationGoalsOverviewApi(query).then((response) => {
      setOrganisationLoader(false);
      if (response.statusCode == 200) {
        setOrganisationOverview(response.data[0] ? response.data[0] : {});
        setLoader((prevLoader) => ({
          ...prevLoader,
          organisation: false,
        }));
      }
    });
  };
  useEffect(() => {
    fetchOrganisationGoalsOverview();
  }, []);

  const fetchSalesGoalsOverview = (query = {}) => {
    setSalesLoader(true);
    setSalesAppliedFilters(query);
    getSalesGoalsOverviewApi(query).then((response) => {
      setSalesLoader(false);
      if (response.statusCode == 200) {
        setSalesOverview(response.data[0] ? response.data[0] : {});
        setLoader((prevLoader) => ({
          ...prevLoader,
          sales: false,
        }));
      }
    });
  };
  useEffect(() => {
    fetchSalesGoalsOverview();
  }, []);

  useEffect(() => {
    getTopPerformersApi().then((response) => {
      if (response.statusCode == 200) {
        setTopPerformers(response.data);
      }
    });
  }, []);

  useEffect(() => {
    getBottomPerformersApi().then((response) => {
      if (response.statusCode == 200) {
        setBottomPerformers(response.data);
      }
    });
  }, []);

  const download = () => {
    downloadReportLoaderSet(true);
    downloadReport()
      .then((response) => {
        downloadReportLoaderSet(false);
        if (response) {
          downloadFileType(response, 'Goal Tracking Dashboard', '.pdf');
        } else {
          console.log(response, 'err');
        }
      })
      .catch((err) => {
        console.error(err, 'err');
      });
  };

  return (
    <>
      <section className={styles.overViewSection}>
        <div className={styles.overviewWrapper}>
          <Grid container direction="row" spacing={3} className={styles.downloadRow}>
            <Grid item xs={12} className="textRight">
              <button
                className={`paragraph downloadBtn ${downloadReportLoader ? 'disabledBtn' : ''}`}
                onClick={() => download()}
              >
                <img src={imageBasePath + 'download.svg'} alt="download icon" />
                {downloadReportLoader ? 'Downloading...' : 'Download Report'}
              </button>
            </Grid>
          </Grid>
          {FeatureFlag.enabled('REMOVE_SALES_GOALS') ? null : (
            <div className={styles.orgWrapper + ' ' + styles.contentOverviewWrapper}>
              {loader && loader.organisation ? (
                <Shimmer type="TrackingAdminOverviewTitle" />
              ) : (
                <OverviewTitle
                  image="organisation_goals.svg"
                  title="Sales Goals"
                  frequency={organisationOverview?.frequency_text}
                  description={
                    organisationOverview?.description ? organisationOverview?.description : ''
                  }
                  handleApplyFilter={fetchOrganisationGoalsOverview}
                  appliedFilters={organisationAppliedFilters}
                  filterFrequency={organisationOverview?.frequency}
                  minDate={organisationOverview?.published_date}
                  defaultFilter={{
                    start_date: organisationOverview?.filter_from_date,
                    end_date: organisationOverview?.filter_end_date,
                  }}
                />
              )}
              <OrganisationGoalsView overview={organisationOverview} loader={organisationLoader} />
            </div>
          )}

          <div className={styles.salesWrapper + ' ' + styles.contentOverviewWrapper}>
            {loader && loader.sales ? (
              <Shimmer type="TrackingAdminOverviewTitle" />
            ) : (
              <OverviewTitle
                image="sales_goals.svg"
                title="Sales Activity"
                frequency={salesOverview?.frequency_text}
                description={salesOverview?.description ? salesOverview?.description : ''}
                tooltip={
                  <div className={styles.performanceTooltipContent}>
                    <h1 className={styles.info + ' heading6'}>
                      Aggregated planned and achieved target of
                      <span> {salesOverview.total_rep} sales representatives</span>
                    </h1>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      className={styles.performersRow + ' hidden'}
                    >
                      <Grid item xs={8} className="flexNotCentered">
                        <h2 className={styles.tableTitlee + ' heading6'}>
                          Total <span>Sales representatives</span>
                        </h2>
                      </Grid>
                      <Grid item xs={4} className="textRight">
                        <p className={styles.count + ' heading6'}>5</p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      className={styles.performersRow + ' hidden'}
                    >
                      <Grid item xs={8} className="flexNotCentered">
                        <div className={styles.tableTitle + ' heading6'}>
                          <img src={imageBasePath + 'hash_icon_green.svg'} alt="image" />
                          <span className="supporTextSemibold">Leads</span>
                        </div>
                      </Grid>
                      <Grid item xs={4} className="textRight">
                        <p className={styles.count + ' heading6'}>
                          <CustomTargetValue value="50" />
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      className={styles.performersRow + ' hidden'}
                    >
                      <Grid item xs={8} className="flexNotCentered">
                        <div className={styles.tableTitle + ' heading6'}>
                          <img src={imageBasePath + 'hash_icon_green.svg'} alt="image" />
                          <span className="supporTextSemibold">Followups on leads</span>
                        </div>
                      </Grid>
                      <Grid item xs={4} className="textRight">
                        <p className={styles.count + ' heading6'}>
                          <CustomTargetValue value="50" />
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      className={styles.performersRow + ' hidden'}
                    >
                      <Grid item xs={8} className="flexNotCentered">
                        <div className={styles.tableTitle + ' heading6'}>
                          <img src={imageBasePath + 'hash_icon_green.svg'} alt="image" />
                          <span className="supporTextSemibold">of appoinments set</span>
                        </div>
                      </Grid>
                      <Grid item xs={4} className="textRight">
                        <p className={styles.count + ' heading6'}>
                          <CustomTargetValue value="20" />
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      className={styles.performersRow + ' hidden'}
                    >
                      <Grid item xs={8} className="flexNotCentered">
                        <div className={styles.tableTitle + ' heading6'}>
                          <img src={imageBasePath + 'hash_icon_green.svg'} alt="image" />
                          <span className="supporTextSemibold">of appoinments held</span>
                        </div>
                      </Grid>
                      <Grid item xs={4} className="textRight">
                        <p className={styles.count + ' heading6'}>
                          <CustomTargetValue value="10" />
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      className={styles.performersRow + ' hidden'}
                    >
                      <Grid item xs={8} className="flexNotCentered">
                        <div className={styles.tableTitle + ' heading6'}>
                          <img src={imageBasePath + 'hash_icon_green.svg'} alt="image" />
                          <span className="supporTextSemibold">of customers lost</span>
                        </div>
                      </Grid>
                      <Grid item xs={4} className="textRight">
                        <p className={styles.count + ' heading6'}>
                          <CustomTargetValue value="10" />
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                }
                handleApplyFilter={fetchSalesGoalsOverview}
                appliedFilters={salesAppliedFilters}
                filterFrequency={salesOverview?.frequency}
                minDate={salesOverview?.published_date}
                defaultFilter={{
                  start_date: salesOverview?.filter_from_date,
                  end_date: salesOverview?.filter_end_date,
                }}
                type="salesGoals"
              />
            )}
            {FeatureFlag.enabled('SWAP_SALES_GOALS') && <RefreshHubspotData />}

            <AdminSalesGoalsView
              overview={salesOverview}
              loader={salesLoader}
              appliedFilters={salesAppliedFilters}
            />
          </div>

          {!FeatureFlag.enabled('SWAP_SALES_GOALS') && (
            <HubspotTrackingOverview filters={salesAppliedFilters} />
          )}
        </div>
        <div className={styles.performersWrapper}>
          <Grid container direction="row" spacing={3} className={styles.performersRow}>
            <Grid item xs={4} className="flexNotCentered">
              <SalesPerformers users={topPerformers} type="top" />
            </Grid>
            <Grid item xs={4} className="flexNotCentered">
              <SalesPerformers
                users={bottomPerformers.filter((bottom) => {
                  return !topPerformers.find((top) => {
                    return top.uuid === bottom.uuid;
                  });
                })}
                type="bottom"
              />
            </Grid>
            <Grid item xs={4} className="flexNotCentered">
              <ActivityHeatMap type="team" />
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
}
