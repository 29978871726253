import React from 'react';
import { Grid, List, ListItem } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/home.module.scss';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { FeatureFlag } from 'controllers/_exports';

export default function Shimmer(props) {
  return (
    <>
      {props.type == 'AdminModuleSetup' && (
        <div className={styles.moduleSetup}>
          <Skeleton
            variant="circle"
            width={64}
            height={64}
            style={{ background: 'rgba(0, 0, 0, 0.11)', marginBottom: '16px' }}
          />
          <h3 className="heading3">
            <Skeleton variant="text" width="40%" style={{}} />
          </h3>
          <p className={`paragraph ${styles.description}`}>
            <Skeleton variant="text" width="80%" style={{}} />
          </p>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={5}>
              <p className={styles.btnLink}>
                <Skeleton variant="text" width="70%" height="32" style={{}} />
              </p>
            </Grid>
            <Grid item xs={7}>
              <div className={styles.progressInfo + ' flexCentered'}>
                <div className={styles.progressBarBlk}>
                  <Skeleton variant="text" width="60%" style={{}} />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type == 'PageTitle' && (
        <h3 className={`${styles.pageTitle} heading4`}>
          <Skeleton variant="text" width="25%" height={40} style={{}} />
        </h3>
      )}
      {props.type == 'OverallDetailItems' && (
        <>
          {[0, 1, 2, 3].map((item, key) => (
            <Grid key={key} item xs={12} md={3}>
              <div className={styles.overallDetailWrapper + ' flexCentered'}>
                <Skeleton
                  variant="circle"
                  width={48}
                  height={48}
                  style={{ background: 'rgba(0, 0, 0, 0.11)', marginBottom: '16px' }}
                />
                <div
                  className={styles.contentBlk}
                  style={{ marginLeft: '16px', width: '70%', marginTop: '-14px' }}
                >
                  <h3 className="heading2">
                    <Skeleton variant="text" width="35%" height={32} style={{}} />
                  </h3>
                  <p className="paragraph">
                    <Skeleton variant="text" width="60%" height={18} style={{}} />
                  </p>
                </div>
              </div>
            </Grid>
          ))}
        </>
      )}
      {props.type == 'UserHome' && (
        <div className={styles.homePageContent}>
          <section className={styles.homePageProgressSectioin}>
            <div className={styles.userDetailsHeader}>
              <Grid container alignItems="center">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={styles.userInfoWrapper + ' textCenter'}>
                    <div
                      className={styles.userImgBlk}
                      style={{ position: 'absolute', minHeight: '75px' }}
                    >
                      <Skeleton
                        variant="circle"
                        width={75}
                        height={75}
                        style={{ background: 'rgba(0, 0, 0, 0.11)', marginBottom: '16px' }}
                      />
                    </div>
                    <div className={styles.contentBlk} style={{ marginLeft: '90px' }}>
                      <h3 className="heading4">
                        <Skeleton variant="text" width="60%" style={{}} />
                      </h3>
                      <p className="supportTextSemibold">
                        <Skeleton variant="text" width="25%" style={{}} />
                      </p>
                      <p className="supportTextSemibold">
                        <Skeleton variant="text" width="40%" style={{}} />
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={styles.progressContent}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <div className={`${styles.progressContentWrapper}`}>
                    <div className={styles.overviewTitleBlk}>
                      <h3 className={`heading5`}>
                        <Skeleton variant="text" width="30%" height={32} style={{}} />
                      </h3>
                      <Link
                        to="/"
                        className={styles.gotoModuleLink + ' supportTextSemibold'}
                        style={{ width: '80px' }}
                      >
                        <Skeleton variant="text" style={{}} />
                      </Link>
                    </div>
                    <Grid
                      container
                      alignItems=""
                      className={`${styles.trackingProgressContent} ${styles.defaultProgressContent}`}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <div className={styles.checkinAlertMessage}>
                          <Grid container direction="row" alignItems="center" spacing={2}>
                            <Grid item md={8} lg={8} xs={8}>
                              <div className={styles.textBlk}>
                                <Skeleton
                                  variant="circle"
                                  width={60}
                                  height={60}
                                  style={{
                                    background: 'rgba(0, 0, 0, 0.11)',
                                    marginBottom: '16px',
                                  }}
                                />
                                <p
                                  className={`paragraph`}
                                  style={{ marginLeft: '16px', width: '80%', marginTop: '-14px' }}
                                >
                                  <Skeleton variant="text" width="80%" />
                                  <Skeleton variant="text" width="80%" />
                                </p>
                              </div>
                            </Grid>
                            <Grid item lg={4} md={4} xs={4}>
                              <p
                                className={styles.btnBlk}
                                style={{ float: 'right', width: '200px', margin: '-15px 0 0 auto' }}
                              >
                                <Skeleton variant="text" width="70%" height={40} style={{}} />
                              </p>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      {!FeatureFlag.enabled('REMOVE_SALES_GOALS') && (
                        <Grid item xs={12} md={4}>
                          <div className={styles.organizationGoals}>
                            <Grid container style={{ height: '100%' }}>
                              <Grid item xs={12}>
                                {[0, 1].map((key, index) => (
                                  <div
                                    key={index}
                                    className={`${styles.trackingInfoItem} textCenter`}
                                  >
                                    <h3 className={`heading6 ${styles.title}`}>
                                      <Skeleton
                                        variant="circle"
                                        width={22}
                                        height={22}
                                        style={{
                                          background: 'rgba(0, 0, 0, 0.11)',
                                          marginBottom: '16px',
                                          position: 'absolute',
                                        }}
                                      />
                                      <Skeleton
                                        variant="text"
                                        width="40%"
                                        style={{ marginLeft: '32px', display: 'block' }}
                                      />
                                    </h3>
                                    <div className={`${styles.trackingValueWrapper} flexCentered`}>
                                      <h3 className={`heading2`} style={{ width: '40%' }}>
                                        <Skeleton variant="text" style={{}} />
                                      </h3>
                                    </div>
                                  </div>
                                ))}
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={12} md={FeatureFlag.enabled('REMOVE_SALES_GOALS') ? 12 : 8}>
                        <div className={styles.salesGoalsStatus}>
                          <ul className={`listUnstyled ${styles.goalsListing}`}>
                            {[0, 1, 2, 3].map((goal, index) => (
                              <li key={index}>
                                <div className={styles.goalItem}>
                                  <Grid container alignItems="center">
                                    <Grid item xs={7}>
                                      <h3 className={`supportTextSemibold ${styles.title}`}>
                                        <Skeleton
                                          variant="circle"
                                          width={22}
                                          height={22}
                                          style={{
                                            background: 'rgba(0, 0, 0, 0.11)',
                                            marginBottom: '16px',
                                            position: 'absolute',
                                          }}
                                        />
                                        <Skeleton
                                          variant="text"
                                          width="40%"
                                          style={{ marginLeft: '32px', display: 'block' }}
                                        />
                                      </h3>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <div className={styles.achievement + ' flexCentered'}>
                                        <Skeleton variant="text" width="90%" style={{}} />
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={`${styles.progressContentWrapper}`}>
                    <div className={styles.overviewTitleBlk}>
                      <h3 className={`heading5`}>
                        <Skeleton variant="text" width="30%" height={32} style={{}} />
                      </h3>
                      <Link
                        to="/"
                        className={styles.gotoModuleLink + ' supportTextSemibold'}
                        style={{ width: '80px' }}
                      >
                        <Skeleton variant="text" style={{}} />
                      </Link>
                    </div>
                    <Grid
                      container
                      className={`${styles.trainingProgressContent} ${styles.defaultProgressContent}`}
                      alignItems="center"
                    >
                      <Grid item xs={12} md={6}>
                        <Skeleton
                          variant="circle"
                          width={200}
                          height={200}
                          style={{ background: 'rgba(0, 0, 0, 0.11)', marginBottom: '16px' }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={styles.infoWrapper}>
                          <h3 className="heading2">
                            <Skeleton variant="text" style={{}} />
                          </h3>
                          <p className="paragraph">
                            <Skeleton variant="text" style={{}} />
                          </p>
                        </div>
                        <div className={`${styles.infoWrapper}`}>
                          <h3 className="heading2">
                            <Skeleton variant="text" style={{}} />
                          </h3>
                          <p className="paragraph">
                            <Skeleton variant="text" style={{}} />
                          </p>
                        </div>
                        <div className={styles.btnBlk}>
                          <p>
                            <Skeleton variant="text" width="40%" height={32} style={{}} />
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </section>
        </div>
      )}
      {props.type == 'SalesHome' && (
        <div className={styles.homePageContent}>
          <section className={styles.homePageProgressSectioin}>
            <div className={styles.progressContent}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <div className={`${styles.moduleInitWrapper}`}>
                    <Grid container spacing={0} style={{ height: `100%` }}>
                      <Grid item xs={12} md={6}>
                        <Skeleton variant="text" width="100%" height="100%" style={{}} />
                      </Grid>
                      <Grid item xs={12} md={6} style={{ alignSelf: 'center' }}>
                        <div className={styles.contentWrapper}>
                          <h3 className={styles.title + ' heading4'}>
                            <Skeleton variant="text" width="40%" style={{}} />
                          </h3>
                          <p className={`paragraph ${styles.description}`}>
                            <Skeleton variant="text" width="80%" style={{}} />
                          </p>
                          <div className={styles.progressInfoWrapper + ' flexCentered'}>
                            <div className={styles.progressValue} style={{ width: '50px' }}>
                              <p className="supportTextSemibold">
                                <Skeleton variant="text" width="80%" style={{}} />
                              </p>
                            </div>
                            <div className={`${styles.progressBarBlk} `}>
                              <Skeleton variant="text" width="80%" style={{}} />
                            </div>
                          </div>
                          <p className="textCenter">
                            <Skeleton
                              variant="text"
                              width="50%"
                              height={40}
                              style={{ margin: '16px auto 0' }}
                            />
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={`${styles.progressContentWrapper}`}>
                    <div className={styles.overviewTitleBlk}>
                      <h3 className={`heading5`}>
                        <Skeleton variant="text" width="30%" height={32} style={{}} />
                      </h3>
                      <Link
                        to="/"
                        className={styles.gotoModuleLink + ' supportTextSemibold'}
                        style={{ width: '80px' }}
                      >
                        <Skeleton variant="text" style={{}} />
                      </Link>
                    </div>
                    <Grid
                      container
                      className={`${styles.trainingProgressContent} ${styles.defaultProgressContent}`}
                      alignItems="center"
                    >
                      <Grid item xs={12} md={6}>
                        <Skeleton
                          variant="circle"
                          width={200}
                          height={200}
                          style={{ background: 'rgba(0, 0, 0, 0.11)', marginBottom: '16px' }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={styles.infoWrapper}>
                          <h3 className="heading2">
                            <Skeleton variant="text" style={{}} />
                          </h3>
                          <p className="paragraph">
                            <Skeleton variant="text" style={{}} />
                          </p>
                        </div>
                        <div className={`${styles.infoWrapper}`}>
                          <h3 className="heading2">
                            <Skeleton variant="text" style={{}} />
                          </h3>
                          <p className="paragraph">
                            <Skeleton variant="text" style={{}} />
                          </p>
                        </div>
                        <div className={styles.btnBlk}>
                          <p>
                            <Skeleton variant="text" width="40%" height={32} style={{}} />
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
