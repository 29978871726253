import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toastFlashMessage } from 'utils';
import {
  getListUsersService,
  createLearningPath,
  getListLearningPaths,
  getTrainingModulesForPath,
  getLearningPathDetails,
  changeStatusLearningPath,
  updateLearningPath,
  getLearningPathInProgress,
  getLPAssignedToMe,
  getLearningPathAssignedToMe,
  startLearningPath,
  getLearningPathDetailsList,
  getLearningPathProgressList
} from './LearningPaths.services';

export const LEARNING_PATHS_CACHE_KEYS = {
  LIST_USERS: 'learning_paths_list_users',
  LIST_LEARNING_PATHS: 'learning_paths_list',
  PATH_IN_PROGRESS: 'learning_path_in_progress',
  LEARNING_PATH: 'learning-path-details',
  ORG_MODULES: 'organization_modules',
  REP_LEARNING_PATH: 'learning-path-assigned_to_me',
  LEARNING_PATH_YET: 'learning_paths_yet_to_start',
  LEARNING_PATH_COMPLETED: 'learning_paths_completed',
  LEARNING_PATH_DETAILS: 'learning_paths_details',
  LEARNING_PATH_PROGRESS: 'learning_paths_progress',
};

export const useGetListUsersQuery = () => {
  return useQuery({
    queryKey: [LEARNING_PATHS_CACHE_KEYS.LIST_USERS],
    queryFn: getListUsersService,
  });
};

export const useCreateLearningPath = () => {
  const queryClient = useQueryClient();
  return useMutation(({ learningPath }) => createLearningPath({ learningPath }), {
    onSuccess: (response) => {
      toastFlashMessage(response?.message, 'success');
      queryClient.invalidateQueries([LEARNING_PATHS_CACHE_KEYS.LIST_LEARNING_PATHS]);
    },
  });
};

export const useGetListLearningPathsQuery = () => {
  return useQuery({
    queryKey: [LEARNING_PATHS_CACHE_KEYS.LIST_LEARNING_PATHS],
    queryFn: getListLearningPaths,
  });
};

export const useUpdateStatusLearningPath = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ status, learningPathUuid }) => changeStatusLearningPath({ status, learningPathUuid }),
    {
      onSuccess: (response, data) => {
        toastFlashMessage(response?.message, 'success');
        queryClient.invalidateQueries([LEARNING_PATHS_CACHE_KEYS.LIST_LEARNING_PATHS]);
        queryClient.invalidateQueries([
          LEARNING_PATHS_CACHE_KEYS.LEARNING_PATH,
          data?.learningPathUuid,
        ]);
      },
    }
  );
};

export const useGetLearningPathQuery = ({ uuid, enabled }) => {
  return useQuery({
    queryKey: [LEARNING_PATHS_CACHE_KEYS.LEARNING_PATH, uuid],
    queryFn: () => getLearningPathDetails(uuid),
    enabled,
  });
};

export const useGetLPAssignedToMeQuery = ({ uuid }) => {
  return useQuery({
    queryKey: [LEARNING_PATHS_CACHE_KEYS.REP_LEARNING_PATH, uuid],
    queryFn: () => getLPAssignedToMe(uuid),
  });
};

// UPDATE PATH MODULES
export const useGetTrainingModulesForPathQuery = ({ enabled }) => {
  return useQuery({
    queryKey: [LEARNING_PATHS_CACHE_KEYS.ORG_MODULES],
    queryFn: getTrainingModulesForPath,
    enabled,
  });
};

export const useUpdateLearningPathMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(({ uuid, body }) => updateLearningPath({ uuid, body }), {
    onSuccess: (response) => {
      toastFlashMessage(response?.message, 'success');
      queryClient.invalidateQueries([LEARNING_PATHS_CACHE_KEYS.LIST_LEARNING_PATHS]);
      queryClient.invalidateQueries([
        LEARNING_PATHS_CACHE_KEYS.LEARNING_PATH,
        response.learning_path.uuid,
      ]);
    },
  });
};

export const useGetLearningPathInProgressQuery = () => {
  return useQuery({
    queryKey: [LEARNING_PATHS_CACHE_KEYS.PATH_IN_PROGRESS],
    queryFn: getLearningPathInProgress,
  });
};

export const useGetLearningPathAssignedToMeQuery = () => {
  return useQuery({
    queryKey: [LEARNING_PATHS_CACHE_KEYS.LEARNING_PATH_COMPLETED],
    queryFn: () => getLearningPathAssignedToMe(),
  });
};

export const useStartLearningPathMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ uuid }) => startLearningPath({ uuid }),
    onSuccess: (response) => {
      queryClient.invalidateQueries([LEARNING_PATHS_CACHE_KEYS.PATH_IN_PROGRESS]);
      queryClient.invalidateQueries([LEARNING_PATHS_CACHE_KEYS.LEARNING_PATH_COMPLETED]);
      toastFlashMessage(response?.message, 'success');
    },
  });
};

export const useGetLearningPathDetailsListQuery = () => {
  return useQuery({
    queryKey: [LEARNING_PATHS_CACHE_KEYS.LEARNING_PATH_DETAILS],
    queryFn: () => getLearningPathDetailsList(),
  });
};

export const useGetLearningPathProgressListQuery = () => {
  return useQuery({
    queryKey: [LEARNING_PATHS_CACHE_KEYS.LEARNING_PATH_PROGRESS],
    queryFn: () => getLearningPathProgressList(),
  });
};