import { Grid } from '@material-ui/core';
import React from 'react';
import OverallDetailItem from './OverallDetailItem';
import { imageBasePath } from 'constants';
import { tooltipTexts } from 'constants';
import { FeatureFlag } from 'controllers/_exports';

const HomeCardStatistics = ({ overallDetails }) => {
  const newStatistics = FeatureFlag.enabled('NEW_HOME_STATISTICS');
  return (
    <>
      <Grid item xs={12} md={6} lg={3}>
        {newStatistics ? (
          <OverallDetailItem
            image={`${imageBasePath}onboard_completion_icon.svg`}
            value={`${overallDetails?.average_overall_completion_rate || 0}%`}
            title={`Onboarding Completion`}
            description={tooltipTexts.onboarding_completion}
          />
        ) : (
          <OverallDetailItem
            image={`${imageBasePath}contract_value_icon.svg`}
            value={overallDetails.average_contract_value}
            title={`ACV`}
            unit={`dollar`}
            description={tooltipTexts.average}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <OverallDetailItem
          image={`${imageBasePath}compeleion_rate_icon.svg`}
          value={
            overallDetails.training_completion_rate
              ? `${Math.round(overallDetails.training_completion_rate)}%`
              : overallDetails.training_completion_rate
          }
          title={`Training Completion`}
          description={tooltipTexts.training}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {newStatistics ? (
          <OverallDetailItem
            image={`${imageBasePath}roleplay_completion_icon.svg`}
            value={`${overallDetails?.average_roleplays_completion_value || 0}%`}
            title={`Roleplay Completion`}
            description={tooltipTexts.roleplay_completion}
          />
        ) : (
          <OverallDetailItem
            image={`${imageBasePath}win_rate_icon.svg`}
            value={
              overallDetails.customer_win_rate
                ? `${Math.round(overallDetails.customer_win_rate)}%`
                : overallDetails.customer_win_rate
            }
            title={`Win rate`}
            description={tooltipTexts.winrate}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <OverallDetailItem
          image={`${imageBasePath}ramp_time_icon.svg`}
          value={overallDetails.ramp_time}
          title={`Ramp time`}
          description={tooltipTexts.ramp_time}
        />
      </Grid>
    </>
  );
};

export default HomeCardStatistics;
