import React from 'react';
import Picture from '../../components/Picture/Picture';
import styles from './PostContent.module.scss';
import { useMemo } from 'react';
import Icon from '../../components/Icon/Icon';
import StreamUtils from 'features/stream/StreamConstants';
import PostTextArea from '../../components/PostTextArea/PostTextArea';
import Timestamp from '../../components/Timestamp/Timestap';
import { DeletePost } from '../DeletePost/DeletePost';
import FeaturedPost from '../../components/FeaturedPost/FeaturedPost';
import { getContentTypeImg, getContentTypeURL } from 'utils';
import { imageBasePath } from 'constants';
import { FeatureFlag } from 'controllers/_exports';
import VideoPlayer from 'shared_ui_components/VideoPlayer/VideoPlayer';

const PostContent = ({ data = {} }) => {
  const color = data?.category?.color_code || '#000';
  const userName = useMemo(() => StreamUtils.getUsername(data?.user), [data]);

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.headerActions}>
          <Picture
            profile_pic={data.user.profile_pic}
            first_name={data.user.first_name}
            email={data.user.email}
          />
          <div>
            <label className={styles.name}>{userName}</label>
            <Timestamp creationDate={data?.created_at} post={true} />
          </div>
        </div>

        <div className={styles.headerActions}>
          <FeaturedPost postData={data} />
          <span className={styles.category} style={{ color, borderColor: color }}>
            {data.category.name}
            <Icon
              withBackground={false}
              width={14}
              height={14}
              src={data?.category?.picture_icon}
            />
          </span>
          <DeletePost postId={data?.id} data={data} />
        </div>
      </div>

      <div className={styles.postTextArea}>
        <div className={styles.textArea} disabled>
          <PostTextArea initialValue={data?.post} />
        </div>
      </div>
      {FeatureFlag.enabled('JUNE_REQUIREMENTS') && (
        <a href={data?.additional_file.url_file} target="_blannk">
          <div className={styles.postMediaArea}>
            {Object.keys(data?.additional_file).length > 0 && (
              <div className="filePreviewComponent">
                <div className="fileWrapper">
                  {data?.additional_file.type?.includes('image') &&
                  data?.additional_file.url_file ? (
                    <img
                      className="imagePreview"
                      src={data?.additional_file.url_file}
                      alt="UploadedImage"
                    />
                  ) : data?.additional_file.type?.includes('video') ? (
                    <div className={styles.video}>
                    <VideoPlayer src={data?.additional_file.url_file} />
                    </div>
                  ) : data?.additional_file.type ? (
                    getContentTypeImg(getContentTypeURL(data?.additional_file.type).id)
                  ) : (
                    <img src={imageBasePath + 'default_featured_grey.png'} alt="text" />
                  )}
                  {!(
                    data?.additional_file.type === 'image' || data?.additional_file.type === 'video'
                  ) && data?.additional_file.url_file ? (
                    <div className="contentWrapper">
                      <h6 className="heading6">{data?.additional_file.url_file.split('/')[9]}</h6>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </a>
      )}
    </>
  );
};

export default PostContent;
