import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import AddTrainingModulesBtn from '../UpdateContents/AddTrainingModules/AddTrainingModulesBtn';
import TrainingModulesModal from '../UpdateContents/AddTrainingModules/TrainingModulesModal';
import { useGetLearningPathQuery } from '../../LearningPaths.queries';
import styles from './LearningPathDetails.module.scss';
import ListOfContents from './ListOfContents';
import { useLearningPathsStore } from '../../LearningPaths.store';
import UserAvatars from './UserAvatars';
import StatusLabel from 'shared_elements/StatusLabel/StatusLabel';
import { PATH_STATUS, usePathValidations } from '../../LearningPaths.utils';
import ChangeStatusButton from './ChangeStatusButton';
import { imageBasePath } from 'constants';
import DateTime from 'infrastructure/Datetime';
import EditLearningPath from '../EditLearningPath/EditLearningPath';
import User from 'infrastructure/User';

const LearningPathDetails = () => {
  const location = useLocation();
  const history = useHistory();

  const uuid = useMemo(() => location?.pathname?.replace('/learning-path/', ''), [location]);
  if (!uuid) history.push('/training?active=learningPaths');

  const { data } = useGetLearningPathQuery({ uuid, enabled: !!uuid });
  const { isPathOwner } = usePathValidations({ learningPath: data });
  const { setContents, setLearningPath, resetLearningPath, setCurrentLearningPath, openEditModal } =
    useLearningPathsStore();

  const usersList = useMemo(
    () =>
      data?.users.map((user) => {
        return { label: User.getUserName(user), value: user.uuid };
      }),
    [data]
  );
  useEffect(() => {
    if (!data) return;
    setContents(data?.contents || []);
    setLearningPath(data);
    setCurrentLearningPath({
      deadline: DateTime.formatDate(new Date(data?.deadline)) + ' 00:00:00',
      users: usersList,
      title: data?.title,
      description: data?.description,
      uuid: data?.uuid,
    });
  }, [data, setContents, setLearningPath, setCurrentLearningPath, usersList]);

  useEffect(() => {
    return function clean() {
      setContents([]);
      resetLearningPath();
    };
  }, [setContents, resetLearningPath]);

  const handleBack = () => {
    history.push('/training?active=learningPaths');
  };

  return (
    <div>
      <EditLearningPath />
      <div className={styles.infoContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.titleContainerText}>
            <button onClick={handleBack} className={styles.backBtn}>
              <img src={imageBasePath + 'back_arrow.svg'} alt="img" />
            </button>
            <div>
              <div className={styles.title}>
                {data?.title || ''}{' '}
                <StatusLabel
                  published={data?.status === PATH_STATUS.PUBLISH}
                  draft={data?.status === PATH_STATUS.DRAFT}
                />
              </div>
              <p className={styles.description}>{data?.description || ''}</p>
            </div>
          </div>
        </div>

        <div className={styles.buttons}>
          <UserAvatars users={data?.users} />
          {isPathOwner && (
            <span className={styles.buttonsEdit} onClick={openEditModal}>
              Edit details
            </span>
          )}
          <ChangeStatusButton />
        </div>
      </div>
      <div className={styles.contentContainer}>
        <AddTrainingModulesBtn />
        <ListOfContents />
        <TrainingModulesModal />
      </div>
    </div>
  );
};

export default LearningPathDetails;
