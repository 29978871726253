import { roles } from 'constants';

const Organization = (function () {
  // Organization info
  const getSelectedOrg = () => JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg;

  const updateOrgStorage = ({ key, value }) => {
    let updatedInfo = JSON.parse(localStorage.getItem('userInfo'));
    updatedInfo.selectedOrg = {
      ...updatedInfo.selectedOrg,
      [key]: value,
    };
    localStorage.setItem('userInfo', JSON.stringify(updatedInfo));
  };

  // User info
  const check = (role) => getSelectedOrg()?.user_role === roles.find((r) => r.slug === role)?.id;
  const getUserInfo = () => JSON.parse(localStorage.getItem('userInfo'))?.user;

  const isOwner = () => check('primary_admin');
  const isAdmin = () => check('admin');
  const isLead = () => check('sales_lead');
  const isRep = () => check('user');

  return { getSelectedOrg, getUserInfo, isAdmin, isOwner, isLead, isRep, updateOrgStorage };
})();

export default Organization;
