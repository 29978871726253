import { Grid } from '@material-ui/core';
import styles from '../../../assets/styles/tracking.module.scss';
import {
  SalesTeamMembers,
  AdminSalesGoalsView,
  OverviewTitle,
  OrganisationGoalsView,
  MemberInfo,
  TeamCheckin,
  TeamCheckinDetails,
  ActivityHeatMap,
  Shimmer,
} from '.';
import { useState, useEffect } from 'react';
import {
  getUsersListApi,
  getOrganisationGoalsOverviewApi,
  getSalesGoalsOverviewApi,
  getCurrentCheckinApi,
} from '../apiServices';
import { SearchBar } from '../../../shared_elements';
import { goalFrequencies } from '../../../constants';
import { format } from 'date-fns';
import { NoResult } from '../../../shared_ui_components';
import HubspotTrackingOverview from 'features/hubspot/components/HubspotTrackingOverview/HubspotTrackingOverview';
import { FeatureFlag } from 'controllers/_exports';
import RefreshHubspotData from 'features/hubspot/components/RefreshHubspotData';

export default function TeamOverview(props) {
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchVal, setSearchVal] = useState('');
  const [activeUser, setActiveUser] = useState({});
  const [organisationOverview, setOrganisationOverview] = useState('');
  const [salesOverview, setSalesOverview] = useState('');
  const [actualUsers, setActualUsers] = useState([]);
  const [checkinDetails, setCheckinDetails] = useState();
  const [checkinDetailsLoader, setCheckinDetailsLoader] = useState(true);
  const [activeCheckinDate, setActiveCheckinDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [organisationAppliedFilters, setOrganisationAppliedFilters] = useState({});
  const [salesAppliedFilters, setSalesAppliedFilters] = useState({});
  const [organisationLoader, setOrganisationLoader] = useState(false);
  const [salesLoader, setSalesLoader] = useState(false);

  const fetchUsersList = (query = {}) => {
    getUsersListApi(query).then((response) => {
      if (response.statusCode == 200) {
        setUsers(response.data);
        setActualUsers(response.data);
        setLoader(false);
        if (response.data?.length) {
          const activeUser = response.data[0];
          setActiveUser(activeUser);
          fetchOrganisationOverview({ user: activeUser?.user?.uuid });
          fetchSalesGoalsOverview({ user: activeUser?.user?.uuid });
          handleViewCheckinDetails(activeCheckinDate, activeUser?.user?.uuid);
        } else {
        }
      }
    });
  };
  const fetchOrganisationOverview = (query = {}) => {
    if (FeatureFlag.enabled('REMOVE_SALES_GOALS')) return;
    setOrganisationLoader(true);
    setOrganisationAppliedFilters(query);
    getOrganisationGoalsOverviewApi(query).then((response) => {
      setOrganisationLoader(false);
      if (response.statusCode == 200) {
        setOrganisationOverview(response.data[0] ? response.data[0] : {});
      }
    });
  };
  const fetchSalesGoalsOverview = (query = {}) => {
    setSalesAppliedFilters(query);
    setSalesLoader(true);
    getSalesGoalsOverviewApi(query).then((response) => {
      setSalesLoader(false);
      if (response.statusCode == 200) {
        setSalesOverview(response.data[0] ? response.data[0] : {});
      }
    });
  };

  useEffect(() => {
    fetchUsersList();
  }, []);

  const handleSearch = (value) => {
    setSearchVal(value);
    if (value && value.trim()) {
      let val = value.trim().toLowerCase();
      let filteredUsers = actualUsers.filter((user) => {
        var full_name =
          user?.user?.first_name || user?.user?.last_name
            ? `${user?.user.first_name ? user?.user.first_name : user?.user?.last_name} ${
                user?.user?.first_name && user?.user?.last_name ? user?.user?.last_name : ''
              }`
            : ``;
        return (
          (full_name ? full_name.toLowerCase().includes(val) : false) ||
          user?.user?.email.toLowerCase().includes(val)
        );
      });
      setUsers(filteredUsers);
    } else {
      setUsers(actualUsers);
    }
  };
  const handleChangeUser = (user) => {
    setActiveUser(user);
    fetchOrganisationOverview({ user: user?.user?.uuid });
    fetchSalesGoalsOverview({ user: user?.user?.uuid });
    setActiveCheckinDate(format(new Date(), 'yyyy-MM-dd'));
    handleViewCheckinDetails(format(new Date(), 'yyyy-MM-dd'), user?.user?.uuid);
  };
  const handleViewCheckinDetails = (date, userId = '') => {
    setCheckinDetailsLoader(true);
    setActiveCheckinDate(date);
    getCurrentCheckinApi({ user: userId ? userId : activeUser?.user?.uuid, date }).then(
      (response) => {
        if (response.statusCode == 200) {
          setCheckinDetailsLoader(false);
          setCheckinDetails(response.data);
        }
      }
    );
  };
  return (
    <div className={styles.teamOuterWrapper}>
      <section className={styles.teamSection + ' ' + styles.overViewSection}>
        {loader ? (
          <Shimmer type="TeamOverview" />
        ) : actualUsers.length ? (
          <div className={styles.teamContainer + ' ' + styles.overviewWrapper}>
            <Grid container direction="row" spacing={3} className={styles.mainTeamRow}>
              <Grid item xs={3} className={styles.leftBlk}>
                <div className={styles.salesTeamMembers}>
                  <div className={styles.topBlk}>
                    <Grid container direction="row" alignItems="center" className={styles.topRow}>
                      <Grid item xs={12} className={styles.leftBlk}>
                        <h4 className="heading6">{`All Sales Team ( ${users?.length} )`}</h4>
                        <div className={styles.searchBlk}>
                          <SearchBar
                            placeholder="Search for a user"
                            handleSearch={handleSearch}
                            handleBlur={(e) => setSearchVal(e.target.value.trim())}
                            text={searchVal}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <SalesTeamMembers
                    activeUser={activeUser}
                    users={users}
                    handleChangeUser={handleChangeUser}
                  />
                </div>
              </Grid>
              <Grid item xs={9} className={styles.rightBlk}>
                <div className={styles.teamContentOuterWrapper}>
                  <MemberInfo user={activeUser} />
                  {FeatureFlag.enabled('REMOVE_SALES_GOALS') ? null : (
                    <div className={styles.orgWrapper + ' ' + styles.contentOverviewWrapper}>
                      <OverviewTitle
                        image="organisation_goals.svg"
                        title="Sales Goals"
                        frequency={organisationOverview?.frequency_text}
                        description={
                          organisationOverview?.description ? organisationOverview?.description : ''
                        }
                        handleApplyFilter={(query = {}) =>
                          fetchOrganisationOverview({ ...query, user: activeUser?.user?.uuid })
                        }
                        appliedFilters={organisationAppliedFilters}
                        minDate={organisationOverview?.published_date}
                        defaultFilter={{
                          start_date: organisationOverview?.filter_from_date,
                          end_date: organisationOverview?.filter_end_date,
                        }}
                        filterFrequency={organisationOverview?.frequency}
                      />
                      <OrganisationGoalsView
                        overview={organisationOverview}
                        loader={organisationLoader}
                      />
                    </div>
                  )}
                  <div className={styles.salesWrapper + ' ' + styles.contentOverviewWrapper}>
                    <OverviewTitle
                      image="sales_goals.svg"
                      title="Sales Activity"
                      frequency={salesOverview?.frequency_text}
                      description={salesOverview?.description ? salesOverview?.description : ''}
                      handleApplyFilter={(query = {}) =>
                        fetchSalesGoalsOverview({ ...query, user: activeUser?.user?.uuid })
                      }
                      appliedFilters={salesAppliedFilters}
                      filterFrequency={salesOverview?.frequency}
                      minDate={salesOverview?.published_date}
                      defaultFilter={{
                        start_date: salesOverview?.filter_from_date,
                        end_date: salesOverview?.filter_end_date,
                      }}
                      type="salesGoals"
                    />
                    {FeatureFlag.enabled('SWAP_SALES_GOALS') && (
                      <RefreshHubspotData id={activeUser?.user?.uuid} />
                    )}
                    <AdminSalesGoalsView
                      overview={salesOverview}
                      userId={activeUser?.user?.uuid}
                      appliedFilters={salesAppliedFilters}
                      loader={salesLoader}
                    />
                  </div>
                  {!FeatureFlag.enabled('SWAP_SALES_GOALS') && (
                    <HubspotTrackingOverview
                      userId={activeUser?.user?.uuid}
                      filters={salesAppliedFilters}
                      refetchCheckIns={() =>
                        handleViewCheckinDetails(activeCheckinDate, activeUser?.user?.uuid)
                      }
                    />
                  )}
                  <div className={styles.checkinDetails}>
                    <Grid container direction="row" spacing={0} className={styles.checkinRow}>
                      <Grid item xs={4} className={styles.leftBlk + ' flexNotCentered'}>
                        {/* <TeamCheckin /> */}
                        <ActivityHeatMap
                          type="checkin"
                          userId={activeUser?.user?.uuid}
                          activeCheckinDate={activeCheckinDate}
                          handleViewCheckinDetails={handleViewCheckinDetails}
                        />
                      </Grid>
                      <Grid item xs={8} className={styles.rightBlk + ' flexNotCentered'}>
                        {checkinDetailsLoader ? (
                          <p className="paragraph">Loading...</p>
                        ) : (
                          <TeamCheckinDetails
                            checkinDetails={checkinDetails}
                            activeCheckinDate={activeCheckinDate}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <NoResult image={`no_checkin_icon.svg`} title="No sales reps are available" />
        )}
      </section>
    </div>
  );
}
