import React, { useContext } from 'react';
import { List, ListItem } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { imageBasePath } from '../constants';
import { roleConfig } from './roleConfiguration';
import Hidden from '@material-ui/core/Hidden';
import { GlobalContext } from '../context/GlobalContext';
import { showSubscriptionMenuButton } from '../features/payment/Payment.utils';
import { FeatureFlag, Subscription } from 'controllers/_exports';

function Sidebar(props) {
  // let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};
  // const loggedInUser = userInfo.user;
  const { state, dispatch } = useContext(GlobalContext);

  let location = useLocation();

  const selectedOrg = JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg;
  const showRecruitmentButton = () => selectedOrg?.recruiting_module_enabled;

  return (
    <Hidden>
      <div className="sideBar">
        <img
          className="topRadiusPattern"
          src={imageBasePath + 'top_radius_pattern.svg'}
          alt="image"
        />
        {/*<img className="BottomRadiusPattern" src={imageBasePath+'bottom_radius_pattern.svg'} alt="image" />*/}
        <div className="menuListWrapper">
          <List className="listUnstyled">
            <ListItem className={`listItem ${location.pathname == '/' ? 'active' : ''}`}>
              <Link to="/">
                <img className="activeImg" src={imageBasePath + 'home_active.svg'} alt="Home" />
                <img className="defaultImg" src={imageBasePath + 'home_default.svg'} alt="Home" />
                <p className="supportText">Home</p>
              </Link>
            </ListItem>

            {FeatureFlag.enabled('STREAM') ? (
              <ListItem className={`listItem ${location.pathname === '/pulse' ? 'active' : ''}`}>
                <Link
                  onClick={() => Subscription.allow({ key: Subscription.keys.stream })}
                  to={
                    Subscription.allow({ key: Subscription.keys.stream, openModal: false })
                      ? '/pulse'
                      : (l) => l
                  }
                >
                  <img
                    className="activeImg"
                    src={imageBasePath + 'stream_active.svg'}
                    alt="Pulse"
                  />
                  <img
                    className="defaultImg"
                    src={imageBasePath + 'stream_default.svg'}
                    alt="Stream"
                  />
                  <p className="supportText">Pulse</p>
                </Link>
              </ListItem>
            ) : null}

            {showRecruitmentButton() && roleConfig[state.role?.id] ? (
              roleConfig[state.role?.id].recruitment ? (
                <ListItem
                  className={`listItem ${location.pathname == '/recruitment' ? 'active' : ''}`}
                >
                  <Link to="/recruitment">
                    <img
                      className="activeImg"
                      src={imageBasePath + 'recruit_active.svg'}
                      alt="Recruit"
                    />
                    <img
                      className="defaultImg"
                      src={imageBasePath + 'recruit_default.svg'}
                      alt="Recruit"
                    />
                    <p className="supportText">Recruit</p>
                  </Link>
                </ListItem>
              ) : null
            ) : null}
            <ListItem className={`listItem ${location.pathname == '/onboarding' ? 'active' : ''}`}>
              <Link to="/onboarding">
                <img
                  className="activeImg"
                  src={imageBasePath + 'onboard_active.svg'}
                  alt="Onboard"
                />
                <img
                  className="defaultImg"
                  src={imageBasePath + 'onboard_default.svg'}
                  alt="Onboard"
                />
                <p className="supportText">Onboard</p>
              </Link>
            </ListItem>
            <ListItem
              className={`listItem ${
                location.pathname == '/training' || location.pathname === '/training/:uuid'
                  ? 'active'
                  : ''
              }`}
            >
              <Link to="/training">
                <img
                  className="activeImg"
                  src={imageBasePath + 'training_active.svg'}
                  alt="Training"
                />
                <img
                  className="defaultImg"
                  src={imageBasePath + 'training_default.svg'}
                  alt="Training"
                />
                <p className="supportText">Train</p>
              </Link>
            </ListItem>
            <ListItem className={`listItem ${location.pathname == '/tracking' ? 'active' : ''}`}>
              <Link to="/tracking">
                <img className="activeImg" src={imageBasePath + 'track_active.svg'} alt="Track" />
                <img className="defaultImg" src={imageBasePath + 'track_default.svg'} alt="Track" />
                <p className="supportText">Track</p>
              </Link>
            </ListItem>
            {FeatureFlag.enabled('ROLEPLAY') && (
              <ListItem className={`listItem ${location.pathname === '/roleplay' ? 'active' : ''}`}>
                <Link to="/roleplay">
                  <img
                    className="activeImg"
                    src={imageBasePath + 'roleplay_active.svg'}
                    alt="Roleplay"
                  />
                  <img
                    className="defaultImg"
                    src={imageBasePath + 'roleplay_default.svg'}
                    alt="Roleplay"
                  />
                  <p className="supportText">Roleplay</p>
                </Link>
              </ListItem>
            )}
            {state.role?.id != 4 ? (
              <ListItem className={`listItem ${location.pathname == '/users' ? 'active' : ''}`}>
                <Link to="/users">
                  <img className="activeImg" src={imageBasePath + 'users_active.svg'} alt="Users" />
                  <img
                    className="defaultImg"
                    src={imageBasePath + 'users_default.svg'}
                    alt="Users"
                  />
                  <p className="supportText">Users</p>
                </Link>
              </ListItem>
            ) : null}

            {['primary_admin', 'admin'].includes(state?.role?.slug) &&
            roleConfig[state.role?.id] ? (
              roleConfig[state.role?.id].settings ? (
                <ListItem
                  className={`listItem ${location.pathname == '/settings' ? 'active' : ''}`}
                >
                  <Link to="/settings">
                    <img
                      className="activeImg"
                      src={imageBasePath + 'settings_active.svg'}
                      alt="Settings"
                    />
                    <img
                      className="defaultImg"
                      src={imageBasePath + 'settings_default.svg'}
                      alt="Settings"
                    />
                    <p className="supportText">Settings</p>
                  </Link>
                </ListItem>
              ) : null
            ) : null}
            {showSubscriptionMenuButton({ role: state?.role?.slug }) && (
              <>
                <ListItem
                  className={`listItem ${location.pathname === '/subscription' ? 'active' : ''}`}
                >
                  <Link to="/subscription">
                    <img
                      className="activeImg"
                      src={imageBasePath + 'subscription_active2.png'}
                      alt="Subs"
                    />
                    <img
                      className="defaultImg"
                      src={imageBasePath + 'subscription_default2.png'}
                      alt="Subs"
                    />
                    <p className="supportText">Billing</p>
                  </Link>
                </ListItem>
              </>
            )}
          </List>
        </div>
      </div>
      <div className="sales-Img">
        <img src={imageBasePath + 'logo_single.png'} alt="Subs" />
      </div>
    </Hidden>
  );
}

export default Sidebar;
