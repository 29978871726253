import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  IntroductionCard,
  TrackingSetup,
  OrganisationGoalsDialog,
  SalesGoalsDialog,
  GoalListing,
  SetupComplete,
  AdminOverview,
  TeamOverview,
  Shimmer,
} from './';
import { getTrackingGoalsApi, getUserPreferencesApi } from '../apiServices';
import queryString from 'query-string';
import styles from '../../../assets/styles/tracking.module.scss';
import { imageBasePath } from '../../../constants';
import { DashboardHeader } from '../../../shared_elements';
import { NoResult } from '../../../shared_ui_components';
import { useTrackingStore } from '../tracking.store';
import { useGoalsOrderQuery } from '../tracking.queries';
import { FeatureFlag } from 'controllers/_exports';

export default function AdminTrackingContainer(props) {
  const { role } = props;
  let history = useHistory();
  let location = useLocation();
  const [goals, setGoals] = useState('');
  const [loader, setLoader] = useState({
    tracking: true,
    userPreferences: role == 'sales_lead' ? true : false,
  });
  const [addOrgGoals, setAddOrgGoals] = useState(false);
  const [addSalesGoals, setAddSalesGoals] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');
  const [userPreferences, setUserPreferences] = useState({});

  const saveGoalsInStore = useTrackingStore((state) => state?.setGoalsData);

  const { isLoading } = useGoalsOrderQuery();

  const fetchTrackingGoals = () => {
    getTrackingGoalsApi().then((response) => {
      if (response.statusCode == 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          tracking: false,
        }));
        setGoals(response.data);
        // Save sales activity goals in store
        saveGoalsInStore(response?.data);
      }
    });
  };
  useEffect(() => {
    fetchTrackingGoals();
  }, []);
  useEffect(() => {
    if (props.role == 'sales_lead') {
      getUserPreferencesApi().then((response) => {
        if (response.statusCode === 200) {
          setLoader((prevLoader) => ({
            ...prevLoader,
            userPreferences: false,
          }));
          setUserPreferences(response.data);
        }
      });
    }
  }, []);
  useEffect(() => {
    const querys = queryString.parse(location.search, { decode: true });
    if (Object.keys(querys).length && querys.active) {
      const active = querys.active;
      if (activeTab != active) {
        setActiveTab(active);
      }
    } else {
      setActiveTab('overview');
    }
  }, [location]);

  const handleConfigureGoals = (type) => {
    if (type == 'orgGoals') {
      setAddOrgGoals(true);
    } else {
      setAddSalesGoals(true);
    }
  };
  const handleChageTab = (val) => {
    history.push(`/tracking?active=${val}`);
  };
  return (
    <div className={styles.trackingOuterWrapper}>
      {isLoading || (loader && (loader.tracking || loader.userPreferences)) ? (
        <Shimmer type="AdminLanding" />
      ) : props.role == 'sales_lead' &&
        (userPreferences?.onboarding?.required
          ? !userPreferences?.onboarding?.completed
          : false) ? (
        <div className={styles.dashboard}>
          <DashboardHeader
            dashboardLogo={imageBasePath + 'tracking_menu_icon.svg'}
            title="Goal Tracking"
          />
          <NoResult
            image={`no_checkin.svg`}
            title={`Goal Tracking not available`}
            subtext={`Please finish onboarding before Goal Tracking`}
          />
        </div>
      ) : goals.published_at ? (
        <div className={styles.dashboard}>
          <DashboardHeader
            dashboardLogo={imageBasePath + 'tracking_menu_icon.svg'}
            title="Goal Tracking"
            tabs={[
              {
                value: 'overview',
                title: 'Overview',
                isActive: activeTab == 'overview' ? true : false,
              },
              {
                value: 'team',
                title: 'Team',
                isActive: activeTab == 'team' ? true : false,
              },
              {
                value: 'goals',
                title: 'Goals',
                isActive: activeTab == 'goals' ? true : false,
              },
            ]}
            handleChange={handleChageTab}
          />
          <div className={styles.dashboardContentWrapper}>
            {activeTab == 'overview' ? (
              <AdminOverview />
            ) : activeTab == 'team' ? (
              <TeamOverview />
            ) : (
              <>
                {goals.categories
                  ?.filter((c) =>
                    FeatureFlag.enabled('REMOVE_SALES_GOALS') ? !c?.is_organisation : c
                  )
                  ?.map((category) => (
                    <GoalListing
                      key={category.id}
                      featuredImg={
                        category.is_organisation ? 'organisation_goals.svg' : `sales_goals.svg`
                      }
                      category={category}
                      handleEditGoals={handleConfigureGoals}
                      type={category.is_organisation ? `orgGoals` : `salesGoals`}
                      isAdmin={role == 'sales_lead' ? false : true}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
      ) : role == 'sales_lead' ? (
        <div className={styles.dashboard}>
          <DashboardHeader
            dashboardLogo={imageBasePath + 'tracking_menu_icon.svg'}
            title="Goal Tracking"
          />
          <NoResult
            image="no_checkin.svg"
            title="Goal Tracking not available"
            subtext="Seems goal tracking module is not yet published, kindly check later"
          />
        </div>
      ) : (
        <>
          {goals?.categories?.every((cat) => cat.is_content_added) ? (
            <SetupComplete handlePublishSuccess={() => fetchTrackingGoals()} />
          ) : (
            <IntroductionCard
              completed={
                goals.categories && goals.categories.filter((cat) => cat.is_content_added)?.length
              }
              total={goals.categories?.length}
            />
          )}
          {goals.categories?.map((category) =>
            category.is_content_added ? (
              <GoalListing
                featuredImg={
                  category.is_organisation ? 'organisation_goals.svg' : `sales_goals.svg`
                }
                category={category}
                handleEditGoals={handleConfigureGoals}
                type={category.is_organisation ? `orgGoals` : `salesGoals`}
                isAdmin={role == 'sales_lead' ? false : true}
                key={category.id}
              />
            ) : (
              <TrackingSetup
                featuredImg={
                  category.is_organisation ? 'organisation_goals.svg' : `sales_goals.svg`
                }
                category={category}
                key={category.id}
                type={category.is_organisation ? `orgGoals` : `salesGoals`}
                handleOpenAddGoals={handleConfigureGoals}
              />
            )
          )}
        </>
      )}
      <OrganisationGoalsDialog
        open={addOrgGoals}
        handleClose={() => setAddOrgGoals(false)}
        goalsData={goals?.categories?.find((cat) => cat.is_organisation)}
        salesGoalsFrequency={goals?.categories?.find((cat) => !cat.is_organisation)?.frequency}
        fetchTrackingGoals={fetchTrackingGoals}
      />
      <SalesGoalsDialog
        open={addSalesGoals}
        handleClose={() => setAddSalesGoals(false)}
        goalsData={goals?.categories?.find((cat) => !cat.is_organisation)}
        orgGoalsFrequency={goals?.categories?.find((cat) => cat.is_organisation)?.frequency}
        fetchTrackingGoals={fetchTrackingGoals}
      />
    </div>
  );
}
