import React from 'react';
import styles from '../../../assets/styles/signIn.module.scss';
import { InputText, CustomButton, CustomLink, ExternalLink } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import { Tooltip, List, ListItem } from '@material-ui/core';

export default function SignUpForm(props) {
  const {
    formData,
    error,
    submitLoader,
    passwordValidation,
    socialLoginLoader,
    socialLinkedinLoader,
  } = props;
  var base_url = window.location.origin;

  return (
    <div className={styles.signInContainer}>
      <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="salestable" />
      <h1 className={styles.phraseText}>
        Embark on success with Salestable and join a community of hundreds of winning sales team
      </h1>
      <h1 className={styles.signInText}>Sign Up</h1>
      <p className={`paragraph ${styles.signupText}`}>
        Already have an account ?
        <CustomLink className="linkBtn" linktext={`Sign in`} to="/signin" />
      </p>

      <div className={styles.socialLoginWrapper}>
        <List className={styles.listInline}>
          <ListItem>
            <a
              href={`https://accounts.google.com/o/oauth2/v2/auth?scope=email%20profile&include_granted_scopes=true&response_type=token&state=1CXFac0eAkqBarEbqCbABBUkFppA788811uX7jE&redirect_uri=${base_url}/signup&client_id=621423238106-1riec29e2sjd9ha0p4mmprnkmm5vh451.apps.googleusercontent.com`}
            >
              <img src={imageBasePath + 'google.svg'} alt="Google" />
              <span>{socialLoginLoader ? 'Loading...' : 'Google'}</span>
            </a>
          </ListItem>
          <ListItem>
            <a
              href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=IZOJKueOfkkSSaoG&scope=r_emailaddress%20r_liteprofile&client_id=86o911m0llk2ar&redirect_uri=${base_url}/signup`}
            >
              <img src={imageBasePath + 'linkedin.svg'} alt="LinkedIn" />
              <span>{socialLinkedinLoader ? 'Loading...' : 'LinkedIn'}</span>
            </a>
          </ListItem>
        </List>
      </div>

      <div className={styles.formWrapper}>
        <p className={styles.optionHeading}>
          <span> Sign up using email</span>
        </p>
        <div className="inputGroup">
          <InputText
            id="email"
            label="Work email address"
            placeholder="abc@salestable.ai"
            value={formData.email ? formData.email : ''}
            onChange={(e) => props.handleChange('email', e.target.value.trim())}
            error={error.email}
            className={error.email ? 'errorGroup' : ' '}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                props.handleSubmit();
              }
            }}
          />
        </div>
        <div className="inputGroup passwordGroup">
          <Tooltip
            title={
              <div className={styles.tooltipWrapper}>
                <p className="supportText">Password must contain</p>
                <ul className="listUnstyled">
                  <li className="paragraph">
                    {passwordValidation.minCharacter ? (
                      <img src={imageBasePath + 'right.svg'} alt="image" />
                    ) : (
                      <img src={imageBasePath + 'wrong.svg'} alt="image" />
                    )}
                    <span>
                      <b> 8</b> Characters minimum
                    </span>
                  </li>
                  <li className="paragraph">
                    {passwordValidation.oneNumber ? (
                      <img src={imageBasePath + 'right.svg'} alt="image" />
                    ) : (
                      <img src={imageBasePath + 'wrong.svg'} alt="image" />
                    )}
                    <span>
                      <b> 1</b> Number atleast
                    </span>
                  </li>
                  <li className="paragraph">
                    {passwordValidation.oneUpperCase ? (
                      <img src={imageBasePath + 'right.svg'} alt="image" />
                    ) : (
                      <img src={imageBasePath + 'wrong.svg'} alt="image" />
                    )}
                    <span>
                      <b> 1</b> Uppercase letter atleast (A-Z)
                    </span>
                  </li>
                  <li className="paragraph">
                    {passwordValidation.oneLowerCase ? (
                      <img src={imageBasePath + 'right.svg'} alt="image" />
                    ) : (
                      <img src={imageBasePath + 'wrong.svg'} alt="image" />
                    )}
                    <span>
                      <b> 1</b> Lowercase letter atleast (a-z)
                    </span>
                  </li>
                </ul>
              </div>
            }
            placement="right"
            PopperProps={{
              disablePortal: true,
            }}
            disableHoverListener
          >
            <InputText
              id="password"
              label="Create password"
              type={formData.password_show ? 'text' : 'password'}
              autoComplete="current-password"
              placeholder="********"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => props.handleChange('password', e.target.value)}
              value={formData.password}
              error={error.password}
              className={error.password ? 'errorGroup' : ' '}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  props.handleSubmit();
                }
              }}
            />
          </Tooltip>
          <span className="passwordShow metaText" onClick={() => props.togglePassword()}>
            {formData.password_show ? (
              <img src={imageBasePath + 'eye_hide.svg'} alt="Hide" />
            ) : (
              <img src={imageBasePath + 'eye_show.svg'} alt="Show" />
            )}
          </span>
        </div>
        <div className={styles.submitBlk}>
          <CustomButton
            children={submitLoader ? 'Verifying email' : 'Continue to verify email'}
            className={`primaryBtn submitBtn ${submitLoader ? 'disabledBtn' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.handleSubmit();
            }}
          />
        </div>
      </div>

      <div className={styles.agreeBlk + ' textCenter'} style={{ marginTop: '16px' }}>
        <p className={styles.agreeTitle + ' supportText'}>
          By continuing, you're agreeing to the Salestable's
          <ExternalLink
            href={`/termsandcondition`}
            linktext=" terms & conditions  "
            target={'_blank'}
            className={styles.externalLink + ' linkBtn'}
          />
          and
          <ExternalLink
            href={`/privacypolicy`}
            linktext=" Privacy Policy "
            target={'_blank'}
            className={styles.externalLink + ' linkBtn'}
          />
        </p>
      </div>
    </div>
  );
}
