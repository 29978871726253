import React from 'react';
import { NoResult } from 'shared_ui_components';
import { useLearningPathsStore } from '../../LearningPaths.store';
import Organization from 'infrastructure/Organization';

const NoResults = () => {
  const openModal = useLearningPathsStore((state) => state?.openCreateModal);
  const isRep = Organization.isRep();
  return (
    <NoResult
      image={`NoResultLearningPaths.svg`}
      title={'No Learning paths'}
      subtext={
        <div style={{ maxWidth: '480px', margin: '1rem auto' }}>
          {!isRep ?
            'Create a new learning path to enhance the sales training experience by providing personalized learning paths tailored to each sales rep needs'
          : 'You don´t have any learning path assigned yet'}
        </div>
      }
      btnText={!isRep && 'New Learning path'}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        openModal();
      }}
    />
  );
};

export default NoResults;
