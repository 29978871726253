import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { CustomButton, StartRecordingButton, TextArea } from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/training.module.scss';
import {
  toastFlashMessage,
  getContentTypeImgURL,
  isValidUrl,
  isValidVideoLink,
  isValidAudioLink,
  getSizeInMb,
  formatMegaBytes,
} from '../../../utils';
import { getAllAssessmentQuestions, fileUploadApi, submitAssessmentApi } from '../apiServices';
import Shimmer from './Shimmer';
import AssessmentTextboxOption from './AssessmentTextboxOption';
import { FeatureFlag, ReadOnlyWrapper } from 'controllers/_exports';
import { isReadOnly } from 'controllers/Subscriptions/ReadOnlyWrapper/ReadOnlyWrapper';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useRecorderStore } from 'shared_ui_components/VideoRecorder/VideoRecorder.store';
import { Upload } from 'features/onboarding/components';
import { MultipleChoiceSelector, useAssessment } from '../assessment';

export default function UserAssesmentDialog(props) {
  const { status } = props;
  const [open, setOpen] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [questions, setQuestions] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  const { feedbackContentTypes } = useAssessment();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    history.push(`?openAssessment=${open}`);
  }, [open, history]);

  const handleClose = () => {
    setOpen(false);
    props.handleUserAssesment(false);
    setErrors([]);
    storeQuestions();
  };

  useEffect(() => {
    if (props.open) {
      setLoader(true);
      getAssessmentQuestions();
      setFileUploading(false);
    }
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    const querys = queryString.parse(location.search, { decode: true });
    if (querys?.openAssessment === 'true') {
      setLoader(true);
      getAssessmentQuestions();
      setFileUploading(false);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAssessmentQuestions = () => {
    if (assessmentQuestions?.length > 0) {
      setLoader(false);
      setQuestions(assessmentQuestions);
      return;
    }
    getAllAssessmentQuestions().then((response) => {
      setLoader(false);
      if (response.statusCode === 200) {
        setQuestions(response.data);
      } else {
        toastFlashMessage('Something went wrong please try again');
      }
    });
  };

  const getFeedbackContentType = (type) => {
    let contentType = feedbackContentTypes.find((content) => content.value == type);
    return contentType;
  };

  const handleChange = (e, question) => {
    if (isReadOnly(question)) return;
    let answer = { ...question, answer: { ...question.value, loader: true } };
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    setQuestions(questions.map((qst) => (qst.id === question.id ? answer : qst)));
    if (!question.insert) {
      if (!e?.target?.files[0]) {
        setFileUploading(false);
        let answer = {
          ...question,
          answer: { ...question.value, loader: false },
        };
        setQuestions(questions.map((qst) => (qst.id === question.id ? answer : qst)));
        return;
      }
      if (getSizeInMb(e.target.files[0].size) > userInfo.selectedOrg?.max_file_upload_size ?? 100) {
        let errs = [
          ...errors,
          ...[
            {
              id: question.id,
              errorMsg: `The file size must be less than ${formatMegaBytes(
                userInfo.selectedOrg?.max_file_upload_size ?? 100
              )}, Please try again`,
            },
          ],
        ];
        setErrors(errs);
        let answer = {
          ...question,
          answer: { ...question.value, loader: false },
        };
        setQuestions(questions.map((qst) => (qst.id === question.id ? answer : qst)));
      } else {
        let data = new FormData();
        data.append('file', e.target.files[0], e.target.files[0].name);
        setFileUploading(true);
        fileUploadApi(data).then((res) => {
          if (res.statusCode === 200) {
            let valueUpdated = {
              ...question,
              answer: {
                value: res.data.path,
                link: res.data.url,
                loader: false,
              },
            };
            setQuestions(questions.map((qst) => (qst.id === question.id ? valueUpdated : qst)));
            setFileUploading(false);
          }
        });
        setErrors(errors.filter((err) => err.id !== question.id));
      }
    } else {
      let valueUpdated = {
        ...question,
        insert: true,
        answer: { value: e.target.value, loader: false },
      };
      setQuestions(questions.map((qst) => (qst.id === question.id ? valueUpdated : qst)));
      setErrors(errors.filter((err) => err.id !== question.id));
    }
  };

  const handleChoiceChange = ({ choiceID, question }) => {
    const value = JSON.stringify(choiceID);
    if (isReadOnly(question)) return;
    let valueUpdated = {
      ...question,
      insert: true,
      answer: { value },
    };
    setQuestions(questions.map((qst) => (qst.id === question.id ? valueUpdated : qst)));
    setErrors(errors.filter((err) => err.id !== question.id));
  };

  const handleTextboxChange = (text, question) => {
    if (isReadOnly(question)) return;
    let valueUpdated = {
      ...question,
      insert: true,
      answer: { value: text, loader: false },
    };
    setQuestions(questions.map((qst) => (qst.id === question.id ? valueUpdated : qst)));
    setErrors(errors.filter((err) => err.id !== question.id));
  };
  const handleLinkChange = (link, question) => {
    if (isReadOnly(question)) return;
    let valueUpdated = {
      ...question,
      insert: true,
      answer: { value: link, loader: false },
    };
    setQuestions(questions.map((qst) => (qst.id === question.id ? valueUpdated : qst)));
    setErrors(errors.filter((err) => err.id !== question.id));
  };

  const handleRecordingChange = (videoUrl, question) => {
    if (isReadOnly(question)) return;

    let valueUpdated = {
      ...question,
      insert: true,
      answer: { value: videoUrl, loader: false },
    };
    setQuestions(questions.map((qst) => (qst.id === question.id ? valueUpdated : qst)));
    setErrors(errors.filter((err) => err.id !== question.id));
  };

  const handleSubmit = () => {
    setSubmitLoader(true);
    let errs = [];
    questions.forEach((question) => {
      if (isReadOnly(question)) return;
      if (question.answer_type === 9) {
        if (!question?.answer?.value) {
          errs = [
            ...errs,
            ...[
              {
                id: question.id,
                errorMsg: 'Select an option in order to continue',
              },
            ],
          ];
          setErrors(errs);
        }
      } else if (question.answer_type === 8) {
        if (!isValidUrl(question?.answer?.link ? question.answer.link : question?.answer?.value)) {
          errs = [
            ...errs,
            ...[
              {
                id: question.id,
                errorMsg: 'Please insert a valid link (URL)',
              },
            ],
          ];
          setErrors(errs);
        }
      } else if (question?.answer_type === 7) {
        if (!question.answer.value) {
          errs = [
            ...errs,
            ...[
              {
                id: question.id,
                errorMsg: 'Please record a video',
              },
            ],
          ];
          setErrors(errs);
        }
      } else if (question?.answer_type === 6) {
        // Handle answer when is textbox
        if (!question.answer.value) {
          errs = [
            ...errs,
            ...[
              {
                id: question.id,
                errorMsg: 'Please fill the textbox',
              },
            ],
          ];
          setErrors(errs);
        }
      } else if (!question.answer.value) {
        errs = [...errs, ...[{ id: question.id, errorMsg: 'Please upload your document.' }]];
        setErrors(errs);
      } else if (
        !isValidUrl(question?.answer?.link ? question.answer.link : question?.answer?.value)
      ) {
        errs = [
          ...errs,
          ...[
            {
              id: question.id,
              errorMsg: 'Please insert a valid document link',
            },
          ],
        ];
        setErrors(errs);
      } else if (
        isValidUrl(question?.answer?.link ? question.answer.link : question?.answer?.value)
      ) {
        if (
          question.answer_type === 2 &&
          !isValidAudioLink(question?.answer?.link ? question.answer.link : question?.answer?.value)
        ) {
          errs = [...errs, ...[{ id: question.id, errorMsg: 'Please insert a valid audio link' }]];
          setErrors(errs);
        } else if (
          question.answer_type === 1 &&
          !isValidVideoLink(question?.answer?.link ? question.answer.link : question?.answer?.value)
        ) {
          errs = [...errs, ...[{ id: question.id, errorMsg: 'Please insert a valid video link' }]];
          setErrors(errs);
        } else {
        }
      } else {
        setErrors(errors.filter((err) => err.id !== question.id));
      }
    });

    if (!errs.length) {
      submitAssessmentApi({ questions: questions }).then((response) => {
        setSubmitLoader(false);
        if (response.statusCode === 200) {
          handleClose();
          props.handleAssessmentStatus(
            response.data?.assessment_status ? response.data.assessment_status : status
          );
          toastFlashMessage(response.message, 'success');
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    }

    setSubmitLoader(false);
  };

  const handleInsert = (question, val) => {
    if (isReadOnly(question)) return;
    let answer = { ...question, insert: val };
    setQuestions(questions.map((qst) => (qst.id === question.id ? answer : qst)));
  };

  // Save answers in recording store for restoration after recording ends
  const setAssessmentQuestions = useRecorderStore((s) => s?.setAssessmentQuestions);
  const storeQuestions = () => {
    // Only necessary if a video response type exists
    const hasVideo = questions.some((q) => q?.answer_type === 7);
    if (!hasVideo) return;
    setAssessmentQuestions(questions);
  };

  const assessmentQuestions = useRecorderStore((s) => s?.assessmentQuestions);
  const cleanVideos = useRecorderStore((s) => s?.cleanVideos);

  const handleCancel = () => {
    cleanVideos();
    setAssessmentQuestions([]);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        className={`dialog ${styles.assesmenteditDialog} ${styles.userAssesmentDialog}`}
      >
        <DialogTitle id="add-video-title" className={styles.dialogTitle}>
          <img
            src={imageBasePath + 'close_gray.svg'}
            alt="close icon"
            className="modalCloseIcon"
            onClick={() => {
              handleClose();
              handleCancel();
            }}
          />
          <span className={styles.title + ' heading4'}>
            <img src={imageBasePath + 'setup_training.svg'} alt="image" />
            <span>
              Training Assessment
              <br />
              <span className="paragraph">
                {status && status == 2
                  ? `Submit your assessments based on the provided instructions to receive assessment review`
                  : `Please follow the instructions when taking assessments. You'll receive assessment feedback once you submit`}
              </span>
            </span>
          </span>
        </DialogTitle>
        <DialogContent>
          <div className={styles.dialogBody}>
            <div className={styles.formWrapper}>
              {loader ? (
                <Shimmer type="UserAssessmentDialog" />
              ) : (
                questions.map((question, key) => (
                  <Grid
                    key={key}
                    container
                    direction="row"
                    alignItems="center"
                    spacing={4}
                    className={styles.contentRow}
                  >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <ReadOnlyWrapper data={question}>
                        <div className={styles.assesmentContent}>
                          <h3 className="heading5">
                            <span>{key + 1}.</span> {question.name}
                          </h3>
                          <p className={styles.description + ' supportText'}>
                            {question.description}
                          </p>
                          {question.answer_type === 9 ? (
                            <MultipleChoiceSelector
                              handleChoiceChange={handleChoiceChange}
                              question={question}
                            />
                          ) : question.answer_type === 7 ? (
                            <div className={styles.recordingAssessmentButton}>
                              <Upload
                                handleUploadChange={(data) =>
                                  handleRecordingChange(data[0]?.data?.url || '', question)
                                }
                                isCustom={true}
                                formData={{ content: question?.answer?.value }}
                                page={'assessment'}
                                onRecordingStart={handleClose}
                                onRecordingDiscard={() => setOpen(true)}
                                onRecordingEnd={() => setOpen(true)}
                                finalRecordingID={question?.id}
                                bypassSubscription
                                onAssessmentRemove={() => handleRecordingChange('', question)}
                                handleChange={() => null}
                              />
                            </div>
                          ) : question.answer_type === 6 ? (
                            // Textbox answer type
                            <AssessmentTextboxOption
                              initValue={question?.answer?.value}
                              onChange={(text) => handleTextboxChange(text, question)}
                            />
                          ) : (
                            <div className={styles.uploadVedioBlk}>
                              <div className={styles.uploadBtn}>
                                {question?.answer_type && question?.answer?.value ? (
                                  <p>
                                    {question.insert ? (
                                      <a
                                        className={styles.uploadedFileName + ' link '}
                                        href={
                                          question?.answer?.link
                                            ? question.answer.link
                                            : question?.answer?.value
                                        }
                                        target="_blank"
                                      >
                                        <img
                                          src={imageBasePath + 'default_featured.png'}
                                          alt="text"
                                        />
                                      </a>
                                    ) : question.answer_type === 2 ? (
                                      <audio
                                        controls
                                        // autoplay={autoplay}
                                        className={'audio-element ' + styles.uploadedFileName}
                                        controlsList="nodownload"
                                        // ref={audioEl}
                                        key={
                                          question?.answer?.link
                                            ? question?.answer?.link
                                            : question?.answer?.value
                                        }
                                      >
                                        <source
                                          src={
                                            question?.answer?.link
                                              ? question?.answer?.link
                                              : question?.answer?.value
                                          }
                                          type="audio/ogg"
                                        />
                                        <source
                                          src={
                                            question?.answer?.link
                                              ? question?.answer?.link
                                              : question?.answer?.value
                                          }
                                          type="audio/mpeg"
                                        />
                                        Your browser does not support the audio element.
                                      </audio>
                                    ) : question.answer_type === 1 ? (
                                      <video
                                        width="350"
                                        className={styles.uploadedFileName}
                                        controls
                                        key={
                                          question?.answer?.link
                                            ? question?.answer?.link
                                            : question?.answer?.value
                                        }
                                      >
                                        <source
                                          src={
                                            question?.answer?.link
                                              ? question?.answer?.link
                                              : question?.answer?.value
                                          }
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                      </video>
                                    ) : (
                                      <a
                                        className={styles.uploadedFileName + ' link '}
                                        href={
                                          question?.answer?.link
                                            ? question?.answer?.link
                                            : question?.answer?.value
                                        }
                                        target="_blank"
                                      >
                                        {question.answer?.value ? (
                                          getContentTypeImgURL(question?.answer?.value)
                                        ) : (
                                          <img
                                            src={imageBasePath + 'default_featured_grey.png'}
                                            alt="text"
                                          />
                                        )}
                                      </a>
                                    )}
                                  </p>
                                ) : null}
                                {
                                  <div className={styles.btnWrapper}>
                                    {![8].includes(question.answer_type) && (
                                      <a
                                        className={styles.hiddenUploadBtn}
                                        onClick={() => handleInsert(question, false)}
                                      >
                                        <input
                                          id="email"
                                          type="file"
                                          label="Upload"
                                          accept={
                                            getFeedbackContentType(
                                              question.answer_type
                                            )?.extensions.toString() ?? '*'
                                          }
                                          title=""
                                          onChange={(e) => {
                                            handleChange(e, question);
                                          }}
                                        />
                                      </a>
                                    )}
                                    {![8].includes(question.answer_type) && (
                                      <div>
                                        <CustomButton
                                          className={`primaryBtn ${
                                            loader || question.answer.loader ? 'disabledBtn' : ''
                                          }`}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                          }}
                                        >
                                          {question.answer.loader ? (
                                            'Please wait...'
                                          ) : (
                                            <>
                                              {question?.answer?.value ? 'Re-upload' : 'upload'}{' '}
                                              {getFeedbackContentType(question.answer_type)?.title}
                                            </>
                                          )}
                                        </CustomButton>
                                        <CustomButton
                                          className={`${styles.insertLinkBtn} secondaryBtn ${
                                            loader || question.answer.loader ? 'disabledBtn' : ''
                                          }`}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleInsert(question, true);
                                          }}
                                        >
                                          {
                                            <>
                                              {question?.answer?.value ? 'Re-insert' : 'insert'}{' '}
                                              link
                                            </>
                                          }
                                        </CustomButton>
                                      </div>
                                    )}
                                    {question.insert && question.answer_type !== 8 ? (
                                      <TextArea
                                        className={styles.textarealink}
                                        rows={3}
                                        placeholder="Paste link here"
                                        value={
                                          question?.answer?.link
                                            ? question?.answer?.link
                                            : question?.answer?.value
                                        }
                                        onChange={(e) => handleChange(e, question)}
                                      />
                                    ) : null}
                                    {![6, 8].includes(question.answer_type) && (
                                      <p className={`${styles.supportedFileFormate} supportText`}>
                                        Supported file types:{' '}
                                        {getFeedbackContentType(
                                          question.answer_type
                                        ).extensions.join(', ')}
                                      </p>
                                    )}
                                  </div>
                                }
                              </div>
                              {FeatureFlag.enabled('ASSESSMENT_URL') &&
                                question.answer_type === 8 && (
                                  <TextArea
                                    className={styles.textarealink}
                                    rows={3}
                                    placeholder="Paste link here"
                                    value={
                                      question?.answer?.link
                                        ? question?.answer?.link
                                        : question?.answer?.value
                                    }
                                    onChange={(e) => {
                                      handleLinkChange(e.target.value, question);
                                      // handleChange(e, question)
                                    }}
                                  />
                                )}
                            </div>
                          )}
                        </div>
                      </ReadOnlyWrapper>

                      {errors.map((error, key) =>
                        error.id === question.id ? (
                          <p key={key} className="errorMsg">
                            {error.errorMsg}
                          </p>
                        ) : null
                      )}
                    </Grid>
                  </Grid>
                ))
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <CustomButton
            className={`tertiaryBtn`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClose();
              handleCancel();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            className={`primaryBtn ${submitLoader || fileUploading ? 'disabledBtn' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit();
            }}
          >
            {submitLoader ? 'Submitting...' : 'Submit'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
