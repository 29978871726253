import React from 'react';
import HistoryCard from './HistoryCard';
import Organization from 'infrastructure/Organization';
import { useGetRoleplayHistoryQuery } from 'features/roleplay/Roleplay.queries';
import styles from './History.module.scss';

const History = () => {
  const { data } = useGetRoleplayHistoryQuery({ enabled: Organization.isRep() });
  if (!Organization.isRep()) return null;
  else if (!data?.length > 0) {
    return (
      <>
        <h1 className={styles.historyTitle}>History</h1>
        <p className={styles.message}>Your roleplay history will appear here.</p>
      </>
    );
  }

  return (
    <div>
      <h1 className={styles.historyTitle}>History</h1>
      <div className={styles.container}>
        {data.map((roleplay) => (
          <HistoryCard data={roleplay} key={roleplay?.uuid} />
        ))}
      </div>
    </div>
  );
};

export default History;
