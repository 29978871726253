import styles from '../../../assets/styles/signIn.module.scss';
import {
  InputText,
  CustomButton,
  CustomLink,
  ExternalLink,
  CustomCheckbox,
} from '../../../shared_ui_components';
import { imageBasePath } from '../../../constants';
import { List, ListItem } from '@material-ui/core';
import { useLearningPathsStore } from 'features/training/components/LearningPaths/LearningPaths.store';
import { useEffect } from 'react';

export default function SignUpForm(props) {
  const { formData, error, submitLoader, socialLoginLoader, socialLinkedinLoader } = props;
  var base_url = window.location.origin;

  const { setActiveTabRep, setUserPreferencesLP } = useLearningPathsStore();
  useEffect(() => {
    // Clean the tab if a previous session had the learningpaths tab active
    setActiveTabRep('training');
    setUserPreferencesLP({});
  }, [setActiveTabRep, setUserPreferencesLP]);

  return (
    <div className={styles.signInContainer}>
      <img className={styles.logo} src={imageBasePath + 'logo.svg'} alt="salestable" />
      <h1 className={styles.phraseText}>
        Embark on success with Salestable and join a community of hundreds of winning sales team
      </h1>
      <h1 className={styles.signInText}>Sign In</h1>
      <p className={`paragraph ${styles.signupText}`}>
        Don't have an account yet?
        <CustomLink className="linkBtn" linktext={`Sign up`} to="/signup" />
      </p>

      <div className={styles.socialLoginWrapper}>
        <List className={styles.listInline + ' listInline'}>
          <ListItem className="listInlineItem">
            <a
              href={`https://accounts.google.com/o/oauth2/v2/auth?scope=email%20profile&include_granted_scopes=true&response_type=token&state=1CXFac0eAkqBarEbqCbABBUkFppA788811uX7jE&redirect_uri=${base_url}/signin&client_id=621423238106-1riec29e2sjd9ha0p4mmprnkmm5vh451.apps.googleusercontent.com`}
            >
              <img src={imageBasePath + 'google.svg'} alt="Google" />
              <span>{socialLoginLoader ? 'Loading...' : 'Google'}</span>
            </a>
          </ListItem>
          <ListItem className="listInlineItem">
            <a
              href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=IZOJKueOfkkSSaoG&scope=r_emailaddress%20r_liteprofile&client_id=86o911m0llk2ar&redirect_uri=${base_url}/signin`}
            >
              <img src={imageBasePath + 'linkedin.svg'} alt="LinkedIn" />
              <span>{socialLinkedinLoader ? 'Loading' : 'LinkedIn'}</span>
            </a>
          </ListItem>
        </List>
      </div>

      <div className={styles.formWrapper}>
        <p className={styles.optionHeading}>
          <span> Sign in using email</span>
        </p>
        <div className="inputGroup">
          <InputText
            id="email"
            label="Work email address"
            placeholder="Enter your email address"
            value={formData.email ? formData.email : ''}
            onChange={(e) => props.handleChange('email', e.target.value.trim())}
            error={error.email}
            className={error.email ? 'errorGroup' : ' '}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                props.handleSubmit();
              }
            }}
          />
        </div>
        <div className={styles.passwordGroup + ' inputGroup passwordGroup'}>
          <InputText
            id="password"
            label={<span>Enter password </span>}
            type={formData.password_show ? 'text' : 'password'}
            autoComplete="current-password"
            placeholder="********"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => props.handleChange('password', e.target.value)}
            value={formData.password}
            error={error.password}
            className={error.password ? 'errorGroup' : ' '}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                props.handleSubmit();
              }
            }}
          />
          <span className="passwordShow metaText" onClick={() => props.togglePassword()}>
            {formData.password_show ? (
              <img src={imageBasePath + 'eye_hide.svg'} alt="Hide" />
            ) : (
              <img src={imageBasePath + 'eye_show.svg'} alt="Show" />
            )}
          </span>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={styles.agreeBlk + ' inputGroup'}>
            <CustomCheckbox
              value={formData.remember_me == 1 ? true : false}
              onChange={(val) => props.handleChange('remember_me', val ? 1 : 0)}
              label={
                <p className={styles.agreeTitle + ' supportText'} style={{ marginBottom: '24px' }}>
                  Remember me
                </p>
              }
            />
          </div>
          <CustomLink
            className={styles.forgotpassword + ' linkBtn'}
            linktext="Forgot password?"
            to="/forgotpassword"
          />
        </div>

        <div className={styles.submitBlk}>
          <CustomButton
            children="Sign In"
            className={`primaryBtn submitBtn ${submitLoader ? 'disabledBtn' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.handleSubmit();
            }}
          />
        </div>
      </div>
      <p className={styles.copyRight + ' supportText textCenter'}>
        © {new Date().getFullYear()} Salestable Inc, All rights reserved.
      </p>
      <div className={styles.agreeBlk + ' textCenter'}>
        <p className={styles.agreeTitle + ' supportText'}>
          Read our
          <ExternalLink
            href={`https://www.salestable.ai/terms-and-conditions/`}
            linktext=" Terms & Conditions  "
            target={'_blank'}
            className={styles.externalLink + ' linkBtn'}
          />
          and
          <ExternalLink
            href={`https://www.salestable.ai/privacy-policy/`}
            linktext=" Privacy Policy "
            target={'_blank'}
            className={styles.externalLink + ' linkBtn'}
          />
        </p>
      </div>
    </div>
  );
}
