import { useEffect, useState } from 'react';
import {
  getTrainingModulesListApi,
  getRecentlyAccessedModulesApi,
  submitTrainingFeedbackApi,
  getUserPreferencesApi,
  getAllAssessmentQuestions,
} from '../apiServices';
import {
  TrainingSetupIntro,
  ModuleCard,
  Shimmer,
  ViewFeedbackCard,
  FeedbackAssessmentDialog,
  UserAssesmentDialog,
} from './';
import styles from '../../../assets/styles/training.module.scss';
import { Grid, List, ListItem } from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { imageBasePath } from '../../../constants';
import { FeedbackDialog } from '../../onboarding/components';
import { toastFlashMessage } from '../../../utils';
import { NoResult } from '../../../shared_ui_components';
import { FeatureFlag } from 'controllers/_exports';
import { useLearningPathsStore } from './LearningPaths/LearningPaths.store';
import LearningPathViewRep from './LearningPaths/Container/LearningPathViewRep';

export default function UserTrainingContainer(props) {
  const activeTabRep = useLearningPathsStore((state) => state?.activeTabRep);
  const [loader, setLoader] = useState({
    training: true,
    recentlyAccessed: true,
    userPreferences: true,
  });
  const [moduleDetails, setModuleDetails] = useState([]);
  const [recentlyAccessed, setRecentlyAccessed] = useState('');
  const [feedbackSubmitLoader, setFeedbackSubmittingLoader] = useState(false);
  const [feedbackLoader, setFeedbackLoader] = useState(false);
  const [assesmentFeedback, setAssesmentFeedback] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [userAssesmentDialog, setUserAssesmentDialog] = useState(false);
  const [userPreferences, setUserPreferences] = useState({});
  const [userAssessment, setUserAssessment] = useState({});
  const handleFeedbackAssessment = () => {
    setFeedbackLoader(true);
    setFeedbackLoader(true);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    getAllAssessmentQuestions(userInfo?.user.uuid).then((response) => {
      if (response.statusCode === 200) {
        setFeedbackLoader(false);
        setUserAssessment({
          assessment: response.data,
          assessment_status: moduleDetails.assessment_status,
        });
      }
    });
  };

  const fetchTrainingModulesList = () => {
    setLoader(true);
    getTrainingModulesListApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          training: false,
        }));
        setModuleDetails(response.data);
        if (response.data && response.data.list && response.data.list.length > 0) {
          let isCompleted =
            response.data?.completed_count == response.data?.list?.length &&
            response.data?.list?.length != 0;
          if (isCompleted && !response.data?.rating_given) {
            setOpenFeedback(true);
          }
        }
      }
    });
  };
  const fetchRecentlyAccessedModules = () => {
    getRecentlyAccessedModulesApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          recentlyAccessed: false,
        }));
        setRecentlyAccessed(response.data);
      }
    });
  };
  const handleSubmitFeedback = (formData) => {
    setFeedbackSubmittingLoader(true);
    submitTrainingFeedbackApi(formData).then((response) => {
      setFeedbackSubmittingLoader(false);
      if (response.statusCode == 200) {
        toastFlashMessage(response.message, 'success');
        setOpenFeedback(false);
        fetchTrainingModulesList();
        // if(steps.filter(step => step.))
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  useEffect(() => {
    getUserPreferencesApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          userPreferences: false,
        }));
        setUserPreferences(response.data);
      }
    });
  }, []);
  useEffect(() => {
    fetchRecentlyAccessedModules();
  }, []);
  useEffect(() => {
    fetchTrainingModulesList();
  }, []);

  const handleAssesmentFeedback = (flag) => {
    handleFeedbackAssessment();
    setAssesmentFeedback(flag);
  };

  const handleTakeAssessment = (flag) => {
    setUserAssesmentDialog(flag);
  };

  const handleAssessmentStatus = (status) => {
    setModuleDetails({
      ...moduleDetails,
      assessment_status: status,
    });
  };

  return (
    <>
      {loader && (loader.recentlyAccessed || loader.training || loader.userPreferences) ? (
        <Shimmer type="ModuleCard" />
      ) : (
        <>
          <TrainingSetupIntro
            total={moduleDetails?.total_published_modules}
            completed={moduleDetails.completed_count}
            isAdmin={false}
            readinessIndex={moduleDetails.readiness_index}
            role={props.role}
          />
          
          {activeTabRep === 'training' && moduleDetails.assessment_added && moduleDetails.is_completed_for_assessment ? (
            <div className={styles.assesmentStrip}>
              <ViewFeedbackCard
                handleTakeAssessment={handleTakeAssessment}
                status={moduleDetails.assessment_status}
                handleAssesmentFeedback={handleAssesmentFeedback}
              />
            </div>
          ) : null}
          {activeTabRep === 'training' ? (
            <TrainingModulesList
              userPreferences={userPreferences}
              modules={moduleDetails.list}
              role={props.role}
              type={
                recentlyAccessed &&
                recentlyAccessed?.length &&
                (userPreferences?.onboarding?.required
                  ? userPreferences?.onboarding?.completed
                  : true)
                  ? 'recentlyAccessed'
                  : 'all'
              }
              recentModules={recentlyAccessed}
              loader={loader}
            />
          ) : (
            <LearningPathViewRep />
          )}
        </>
      )}
      <FeedbackDialog
        type="training"
        title={`Congratulations, you have completed your Job Training!`}
        subtitle={`We'd appreciate any feedback you can give us on your Training experience`}
        open={openFeedback}
        handleSubmit={handleSubmitFeedback}
        handleClose={() => {
          setOpenFeedback(false);
        }}
        loader={feedbackSubmitLoader}
      />
      <FeedbackAssessmentDialog
        open={assesmentFeedback}
        handleClose={() => handleAssesmentFeedback(false)}
        feedbackLoader={feedbackLoader}
        assessmentStatus={moduleDetails.assessment_status}
        userAssessment={userAssessment}
        userType="salesrep"
      />
      <UserAssesmentDialog
        open={userAssesmentDialog}
        handleClose={() => setUserAssesmentDialog(false)}
        handleUserAssesment={setUserAssesmentDialog}
        handleAssessmentStatus={handleAssessmentStatus}
        status={moduleDetails.assessment_status}
      />
    </>
  );
}
var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  className: `${styles.modulesSlider} modulesSlider`,
  autoplay: false,
  prevArrow: <img src={imageBasePath + 'next.svg'} alt="Prev" />,
  nextArrow: <img src={imageBasePath + 'next.svg'} alt="Next" />,
  // centerMode: isMobile ? true : false,
  // centerPadding: '24px',
};
function TrainingModulesList(props) {
  const { modules, type = 'all', recentModules = [], userPreferences = {}, loader } = props;

  const filterModules = (activeTab) => {
    switch (activeTab) {
      case '1':
        return modules?.filter(
          (mod) =>
            mod.completed_count && mod.completed_count < mod.content_count && !mod.is_completed
        );
      case '2':
        return modules?.filter((mod) => mod.completed_count === 0 && mod?.content_count > 0);
      case '3':
        return modules?.filter(
          (mod) => mod.is_completed || mod.completed_count == mod.content_count
        );
      case '4':
        return modules?.filter((mod) => mod.total_contents_schedule > 0);
      default:
        return modules;
    }
  };

  const [activeTab, setActiveTab] = useState(
    modules
      ? filterModules(`1`)?.length
        ? '1'
        : filterModules(`2`)?.length
        ? '2'
        : filterModules(`3`).length
        ? '3'
        : filterModules(`4`).length
        ? '4'
        : '1'
      : '1'
  );

  let tabs = [];
  if (FeatureFlag.enabled('SHOW_RECURRING_CONTENT')) {
    tabs = [
      {
        id: '1',
        label: 'In progress',
      },
      {
        id: '2',
        label: 'Yet to start',
      },
      {
        id: '3',
        label: 'Completed',
      },
      {
        id: '4',
        label: 'Recurring',
      },
    ];
  } else
    tabs = [
      {
        id: '1',
        label: 'In progress',
      },
      {
        id: '2',
        label: 'Yet to start',
      },
      {
        id: '3',
        label: 'Completed',
      },
    ];

  const handleChangeTab = (id) => {
    setActiveTab(id);
  };
  useEffect(() => {
    if (modules) {
      setActiveTab(
        filterModules(`1`)?.length
          ? '1'
          : filterModules(`2`)?.length
          ? '2'
          : filterModules(`3`).length
          ? '3'
          : filterModules(`4`).length
          ? '4'
          : '1'
      );
    }
  }, [modules]);
  return (
    <section className={styles.setupModuleSection}>
      <div className={styles.setupModuleWrapper}>
        <Grid container direction="row" alignItems="center" spacing={2} className={styles.titleRow}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {modules && modules.length ? (
              <h2 className="heading4">{type == 'all' ? `All modules` : `Recently Accessed`}</h2>
            ) : loader && (loader.recentlyAccessed || loader.training || loader.userPreferences) ? (
              <Shimmer type="UserModuleCardTitle" />
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={3}
          className={styles.contentRow}
        >
          {type == 'all' ? (
            modules &&
            modules.length &&
            (userPreferences?.onboarding?.required
              ? userPreferences?.onboarding?.completed
              : true) ? (
              modules.map((item, index) => (
                <Grid item xs={4} md={4} lg={4}>
                  <ModuleCard key={index} module={item} index={index} {...props} />
                </Grid>
              ))
            ) : modules ? (
              <Grid item xs={12}>
                <NoResult
                  image={`training_no_state.svg`}
                  title={`Training not available`}
                  subtext={
                    userPreferences?.onboarding?.required && !userPreferences?.onboarding.completed
                      ? `Please finish onboarding before training`
                      : `Seems training module is not yet published, kindly check later`
                  }
                />
              </Grid>
            ) : (
              <Shimmer type="UserModuleCard" />
            )
          ) : (
            <Grid item xs={12}>
              <div className={styles.modulesSliderOuterWrapper}>
                <Slider {...settings}>
                  {recentModules.map((item, index) => (
                    <ModuleCard key={index} module={item} index={index} {...props} />
                  ))}
                </Slider>
              </div>
            </Grid>
          )}
        </Grid>
        {type == 'recentlyAccessed' ? (
          <>
            <div className={styles.tabListWrapper}>
              <List className="listInline tabList">
                {tabs.map((tab, key) =>
                  filterModules(tab.id)?.length ? (
                    <ListItem
                      key={key}
                      className={`listInlineItem heading6 ${activeTab == tab.id ? 'active' : ''}`}
                      onClick={() => handleChangeTab(tab.id)}
                    >
                      {tab.label}
                    </ListItem>
                  ) : null
                )}
              </List>
            </div>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={3}
              className={styles.contentRow}
            >
              {filterModules(activeTab) && filterModules(activeTab).length ? (
                filterModules(activeTab).map((item, index) => (
                  <Grid item xs={4} md={4} lg={4}>
                    <ModuleCard key={index} module={item} index={index} {...props} />
                  </Grid>
                ))
              ) : modules ? (
                <Grid item xs={12}>
                  <p className="paragraph">No modules available</p>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </>
        ) : null}
      </div>
    </section>
  );
}
