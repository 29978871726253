import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath, tooltipTexts } from '../../../constants';
import { ModuleInit, Shimmer, TrainingOverview, TrackingOverview, OverallDetailItem } from './';
import styles from '../../../assets/styles/home.module.scss';
import {
  getOnboardingOverviewApi,
  getTrainingOverviewApi,
  getTrackingOverviewApi,
  getUserPreferencesApi,
  getOverallDetailsApi,
} from '../apiServices';
import { FeatureFlag, Subscription } from 'controllers/_exports';
import HomeCard from 'features/stream/UI/Analytics/HomeCard/HomeCard';
import HomeCardStatistics from './HomeCardStatistics';
import RoleplayHome from 'features/roleplay/Components/RoleplayHome/RoleplayHome';

export default function SalesLeadHomeContainer(props) {
  const [onboarding, setOnboarding] = useState('');
  const [training, setTraining] = useState('');
  const [tracking, setTracking] = useState('');
  const [loader, setLoader] = useState({
    onboarding: true,
    training: true,
    tracking: true,
    userPreferences: true,
    overall: true,
  });
  const [userPreferences, setUserPreferences] = useState({});
  const [overallDetails, setOverallDetails] = useState('');

  useEffect(() => {
    getOverallDetailsApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          overall: false,
        }));
        setOverallDetails(response.data);
      }
    });
  }, []);

  useEffect(() => {
    getUserPreferencesApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          userPreferences: false,
        }));
        setUserPreferences(response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (FeatureFlag.enabled('REMOVE_SALES_GOALS'))
      return setLoader((prevLoader) => ({
        ...prevLoader,
        tracking: false,
      }));
    getTrackingOverviewApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          tracking: false,
        }));
        setTracking(response.data);
      }
    });
  }, []);
  useEffect(() => {
    getOnboardingOverviewApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          onboarding: false,
        }));
        setOnboarding(response.data);
      }
    });
  }, []);

  useEffect(() => {
    getTrainingOverviewApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          training: false,
        }));
        setTraining(response.data);
      }
    });
  }, []);
  return (
    <>
      {
        <>
          <div className={styles.pageTitle + ''}>
            {loader &&
            (loader.tracking || loader.onboarding || loader.training || loader.userPreferences) ? (
              <Shimmer type="PageTitle" />
            ) : (
              <>
                {(onboarding?.completed_steps == onboarding?.total_steps ||
                  (userPreferences?.onboarding?.required &&
                    userPreferences?.onboarding?.completed)) &&
                training &&
                training.setup_complete &&
                tracking.published_at ? (
                  <h3 className={`${styles.pageTitle} heading4`}>
                    <img src={imageBasePath + 'home_icon.svg'} />
                    <span>Home</span>
                  </h3>
                ) : (
                  <h3 className={`${styles.pageTitle} heading4`}>
                    <span>Go ahead and let's do it</span>
                  </h3>
                )}
              </>
            )}
          </div>

          <div className={styles.homePageContent}>
            <Grid container spacing={3}>
              {loader &&
              (loader.tracking ||
                loader.onboarding ||
                loader.training ||
                loader.userPreferences ||
                loader.overall) ? (
                <Shimmer type="OverallDetailItems" />
              ) : (
                <>
                  {(onboarding?.completed_steps == onboarding?.total_steps ||
                    (userPreferences?.onboarding?.required &&
                      userPreferences?.onboarding?.completed)) &&
                  training &&
                  training.setup_complete &&
                  tracking.published_at ? (
                    <HomeCardStatistics overallDetails={overallDetails} />
                  ) : null}
                </>
              )}
              {loader &&
              (loader.tracking ||
                loader.onboarding ||
                loader.training ||
                loader.userPreferences ||
                loader.overall) ? (
                <Grid item xs={12} md={12}>
                  <Shimmer type="SalesHome" />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} md={6}>
                    {onboarding ? (
                      !FeatureFlag.enabled('REMOVE_SALES_GOALS') &&
                      (onboarding?.completed_steps == onboarding?.total_steps ||
                        (userPreferences?.onboarding?.required &&
                          userPreferences?.onboarding?.completed)) &&
                      training &&
                      training.setup_complete &&
                      tracking.published_at ? (
                        <TrackingOverview overview={tracking.overview[0]} />
                      ) : (
                        <ModuleInit
                          moduleData={{
                            featuredImg: `${imageBasePath}onboarding_init_featured.svg`,
                            icon: `${imageBasePath}onboard_default.svg`,
                            title: `Onboarding`,
                            description: `Onboard your team to keep them on the same page with the vision and mission of the company`,
                            progress: {
                              completed: onboarding?.completed_steps,
                              total: onboarding?.total_steps,
                            },
                            type: 'onboarding',
                            link: '/onboarding',
                          }}
                          disabled={onboarding.published_at ? false : true}
                          type="onboarding"
                        />
                      )
                    ) : (
                      <p className="paragraph">Loading...</p>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {training ? (
                      (training.published_modules < training.total_modules &&
                        !training.setup_complete) ||
                      (userPreferences?.onboarding?.required
                        ? !userPreferences?.onboarding?.completed
                        : false) ? (
                        <ModuleInit
                          moduleData={{
                            featuredImg: `${imageBasePath}training_init_featured.svg`,
                            icon: `${imageBasePath}training_default.svg`,
                            title: `Training`,
                            description: `Train your team with the latest products and services using a rich training library to take your sales team to the next level`,
                            progress: {
                              completed: training.published_modules,
                              total: training.total_modules,
                            },
                            link: '/training',
                          }}
                          type="training"
                          disabled={
                            userPreferences?.onboarding?.required &&
                            !userPreferences?.onboarding?.completed
                              ? true
                              : false
                          }
                        />
                      ) : (
                        <TrainingOverview training={training} />
                      )
                    ) : (
                      <p className="paragraph">Loading...</p>
                    )}
                  </Grid>
                  {FeatureFlag.enabled('STREAM') &&
                    Subscription.allow({ key: Subscription.keys.stream, openModal: false }) && (
                      <Grid item xs={12} md={6}>
                        <HomeCard user="user" />
                      </Grid>
                    )}
                  <RoleplayHome graph />
                </>
              )}
            </Grid>
          </div>
        </>
      }
    </>
  );
}
