import React from 'react';
import RoleplaySelectAssign from './RoleplaySelectAssign';
import RoleplayDeadline from './RoleplayDeadline';
import RoleplayScenarios from './RoleplayScenarios';

const RoleplayModalBodyDetails = () => {
  return (
    <div style={{display: 'flex', gap: '0.5rem', flexDirection: 'column'}}>
      <p className="heading5" style={{marginBottom: '0.5rem'}}>Roleplay details</p>
      <RoleplayScenarios />
      <RoleplaySelectAssign />
      <RoleplayDeadline />
    </div>
  );
};

export default RoleplayModalBodyDetails;
