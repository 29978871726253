import { API } from 'utils/globalApiServices';
export const getListUsersService = async () => {
  const url = 'roleplays/list/users/';
  const { data } = await API.get(url);
  return data?.list || [];
};

export const createLearningPath = async ({ learningPath = {} }) => {
  const url = 'learning-path/create/';
  const data = await API.post(url, { ...learningPath });
  return data;
};

export const getListLearningPaths = async () => {
  const url = `learning-paths/`;
  const { data } = await API.get(url);
  return data || {};
};

export const getLearningPathDetails = async (uuid) => {
  const url = `learning-path/${uuid}/`;
  const { data } = await API.get(url);
  return data || {};
};

export const getLPAssignedToMe = async (uuid) => {
  const url = `/learning-path/${uuid}/assigned-to-me/`;
  const { data } = await API.get(url);
  return data || {};
};

export const getTrainingModulesForPath = async () => {
  const url = `training/modules`;
  const { data } = await API.get(url);
  return data?.list?.filter((module) => module?.published_at && module?.status === 1) || [];
};

export const updateLearningPathContents = async ({ uuid, removed_contents, contents }) => {
  const url = `/learning-path/${uuid}/content/update/`;
  const body = {};
  if (removed_contents?.length > 0) body.removed_contents = removed_contents;
  if (contents?.length > 0) body.contents = contents;
  const data = await API.post(url, body);
  return data;
};

export const changeStatusLearningPath = async ({ status, learningPathUuid }) => {
  const url = `learning-path/${learningPathUuid}/status/`;
  const data = await API.post(url, { status });
  return data;
};

export const updateLearningPath = async ({ uuid, body = {} }) => {
  const url = `learning-path/${uuid}/edit/`;
  const data = await API.put(url, { ...body });
  return data;
};

export const getLearningPathInProgress = async () => {
  const url = `learning-path/in-progress/`;
  const { data } = await API.get(url);
  return data || {};
};

export const getLearningPathAssignedToMe = async () => {
  const url = `learning-paths/assigned-to-me/`;
  const { data } = await API.get(url);
  return data || {};
};

export const startLearningPath = async ({ uuid }) => {
  const url = `/learning-path/${uuid}/start/`;
  const data = await API.post(url);
  return data;
};


export const getLearningPathDetailsList = async () => { 
  const url = `learning-paths/details/`;
  const { data } = await API.get(url);
  return data || {};
};
export const getLearningPathProgressList = async () => {
  const url = `learning-paths/progress/`;
  const { data } = await API.get(url);
  return data || {};
};