import React, { useState, useMemo } from 'react';
import { InputSelect, InputText, TextArea } from 'shared_ui_components';
import { useLearningPathsStore } from '../../LearningPaths.store';
import { useGetListUsersQuery } from '../../LearningPaths.queries';
import DatePicker from 'shared_ui_components/ScheduleTraining/Components/DatePicker';
import styles from './EditLearningPath.module.scss';
import DateTime from 'infrastructure/Datetime';
import User from 'infrastructure/User';

const EditLearningPathContent = () => {
  const { currentLearningPath, setCurrentLearningPath } = useLearningPathsStore();
  const [valueList, setValueList] = useState(currentLearningPath?.users || []);
  const { data: usersList = [] } = useGetListUsersQuery();

  const options = useMemo(() => {
    if (!usersList?.length > 0) return [];
    return usersList.map((user) => {
      return {
        label: User?.getUserName(user?.user),
        value: user.user.uuid,
      };
    });
  }, [usersList]);

  const handleChange = (key, value) => {
    setCurrentLearningPath({
      ...currentLearningPath,
      [key]: value,
    });
  };
  const handleChangeUsers = (values) => {
    delete currentLearningPath.users;
    let newValue = values;
    if (values.some((item) => item.value === -1)) {
      newValue = usersList.map((user) => {
        return {
          label: User?.getUserName(user?.user),
          value: user.user.uuid,
        };
      });
      setValueList(newValue);
      setCurrentLearningPath({ users: newValue, ...currentLearningPath });
      return;
    }
    setValueList(newValue);
    setCurrentLearningPath({ users: newValue, ...currentLearningPath });
  };

  const handleAcceptDate = (query = {}) => {
    setCurrentLearningPath({
      ...currentLearningPath,
      deadline: DateTime.formatDate(new Date(query)) + ' 00:00:00',
    });
  };

  return (
    <div style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column', padding: '1rem' }}>
      <p className="heading5" style={{ marginBottom: '0.5rem' }}>
        Plan details
      </p>
      <InputText
        label={'Title'}
        placeholder={'New learning path'}
        onChange={(e) => handleChange('title', e.target.value)}
        value={currentLearningPath.title}
        onBlur={(e) => handleChange('title', e.target.value.trim())}
      />
      <TextArea
        label={'Description'}
        placeholder={'Write a detailed description to this learning path.'}
        onChange={(e) => handleChange('description', e.target.value)}
        value={currentLearningPath.description}
        onBlur={(e) => handleChange('description', e.target.value.trim())}
        rows={3}
      />
      <InputSelect
        isMulti
        closeMenuOnSelect={true}
        options={[{ label: 'All Users', value: -1 }, ...options]}
        label={'Sales Reps'}
        placeholder={'Select Sales Reps'}
        onChange={(values) => handleChangeUsers(values)}
        noOptionsMessage={() => 'No matches found'}
        isClearable
        value={valueList}
      />
      <div>
        <p className={styles.deadlineTitle}>Deadline</p>
        <DatePicker
          InputLabel={'Deadline'}
          handleApply={(date) => handleAcceptDate(date)}
          defaultFilter={{
            date: currentLearningPath?.deadline
              ? DateTime.formatDate(new Date(currentLearningPath?.deadline))
              : DateTime.formatDate(new Date()),
          }}
        />
      </div>
    </div>
  );
};

export default EditLearningPathContent;
