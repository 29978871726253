import { useState, useMemo } from 'react';
import { Grid, List, ListItem, Menu, MenuItem } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import { CustomButton, CustomTooltipWhite, ProgressBar } from '../../../shared_ui_components';
import { useLocation, useHistory } from 'react-router-dom';
import { publishModuleApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import styles from 'assets/styles/training.module.scss';
import { ConfirmationDialog } from '../../../shared_elements';
import { FeatureFlag, ReadOnlyWrapper } from 'controllers/_exports';
import { isReadOnly } from 'controllers/Subscriptions/ReadOnlyWrapper/ReadOnlyWrapper';
import Contents from 'assets/icons/Contents.svg';
import Recurring from 'assets/icons/Recurring.svg';
import StatusLabel from 'shared_elements/StatusLabel/StatusLabel';

export default function ModuleCard(props) {
  let location = useLocation();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const { index, module, role, modules } = props;
  const [loader, setLoader] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleClick = (event, key) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePublishModule = () => {
    setLoader(true);
    publishModuleApi(module?.uuid).then((response) => {
      setLoader(false);
      if (response.statusCode == 200) {
        props.fetchTrainingModulesList();
        props.handlePublishSuccess(module);
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  const canUserPerformAction = (uuid) => {
    const loggedInUser = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo')).user
      : '';
    if (
      (loggedInUser && loggedInUser?.uuid === uuid) ||
      ['admin', 'primary_admin'].includes(role)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleClickAddContent = (e) => {
    e.stopPropagation();
    e.preventDefault();
    history.push({
      pathname: `/training/${module?.uuid}`,
      state: {
        addContent: true,
        prevPath: `${location.pathname}${location.search ? location.search : ''}`,
      },
    });
  };
  const verifyModuleStatus = useMemo(() => {
    const predefinedEnabledModulesListCount = modules?.filter(
      (mod) => mod.status === 1 && mod.is_predefined
    )?.length;
    if (module.status === 1 && module.is_predefined && predefinedEnabledModulesListCount <= 1) {
      return false;
    } else {
      return true;
    }
  }, [module, modules]);

  const handleRedirect = () => {
    if (isReadOnly(module)) return;
    history.push({
      pathname: `/training/${module?.uuid}`,
      state: { prevPath: `${location.pathname}${location.search ? location.search : ''}` },
    });
  };

  return (
    <>
      <ReadOnlyWrapper data={module}>
        <div onClick={handleRedirect} className={styles.cardWrapper}>
          <div
            className={styles.topBlk}
            style={module.published_at && module.status ? { background: module.color_code } : {}}
          >
            <div className={styles.content}>
              <div className={styles.iconBlk}>
                {module.icon && module.status && module.published_at ? (
                  <img src={module.icon} className={styles.featuredImg} alt="icon" />
                ) : (
                  <svg
                    className={styles.featuredImg}
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="17" cy="17" r="17" fill="#BFD4DF" />
                    <path
                      d="M10.9987 23C10.8219 23 10.6523 22.9298 10.5273 22.8047C10.4023 22.6797 10.332 22.5101 10.332 22.3333V11.6667C10.332 11.4899 10.4023 11.3203 10.5273 11.1953C10.6523 11.0702 10.8219 11 10.9987 11H15.9414L17.2747 12.3333H22.332C22.5088 12.3333 22.6784 12.4036 22.8034 12.5286C22.9285 12.6536 22.9987 12.8232 22.9987 13V15H21.6654V13.6667H16.7227L15.3894 12.3333H11.6654V20.332L12.6654 16.3333H23.9987L22.4587 22.4953C22.4226 22.6395 22.3393 22.7675 22.2222 22.8589C22.105 22.9503 21.9607 23 21.812 23H10.9987ZM22.2907 17.6667H13.7067L12.7067 21.6667H21.2907L22.2907 17.6667Z"
                      fill="white"
                    />
                  </svg>
                )}
                {['user'].includes(role) ? null : module.is_custom ? (
                  <span clasName={styles.customLabel + ' supportText'}>CUSTOM MODULE</span>
                ) : null}
              </div>
              <h3 className={styles.heading3 + ' heading3'}>{module.title}</h3>
              <p className="supportText">
                {module.description && module.description.length > 140
                  ? `${module.description.slice(0, 135)}...`
                  : module.description}
              </p>
              {['user'].includes(role) ? null : module.content_count ? (
                <h4 className={styles.contentCountWrapper + ' supportTextSemibold'}>
                  <img src={`${imageBasePath}content_added_icon.svg`} />
                  {module.content_count}
                  <span>Content added</span>
                </h4>
              ) : null}
              <img
                className={styles.trainingPattern}
                src={imageBasePath + 'training_pattern.png'}
                alt="image"
              />
              {['user'].includes(role) ? (
                module.is_new ? (
                  <span className={styles.newAlert + ' supportText'}>NEW</span>
                ) : null
              ) : (
                <span className={styles.more}>
                  <a
                    aria-controls={'simple-menu-' + index}
                    className={'more'}
                    aria-haspopup="true"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleClick(e);
                    }}
                  >
                    <img
                      className="more"
                      src={imageBasePath + 'more_black.svg'}
                      alt="More"
                      style={{ verticalAlign: 'middle' }}
                    />
                  </a>
                </span>
              )}
              <Menu
                id={'simple-menu-' + index}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl) ? true : false}
                onClose={(e) => {
                  setAnchorEl(null);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className="moreMenu"
              >
                {!module?.disable_edition && (
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.handleEditModule(module);
                      setAnchorEl(null);
                    }}
                  >
                    <img src={imageBasePath + 'edit.svg'} alt="Edit" />
                    <span className="supportText">Edit</span>
                  </MenuItem>
                )}
                {canUserPerformAction(module.created_by?.uuid) && verifyModuleStatus ? (
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setAnchorEl(null);
                      props.handleEnabelDisable(module);
                    }}
                  >
                    {module.status === 0 ? (
                      <img src={imageBasePath + 'check_grey3.svg'} alt="Disable" />
                    ) : (
                      <img src={imageBasePath + 'disable.svg'} alt="Disable" />
                    )}
                    <span className="supportText">{module.status ? `Disable` : `Enable`}</span>
                  </MenuItem>
                ) : null}
                {module.is_custom && canUserPerformAction(module.created_by?.uuid) ? (
                  <MenuItem
                    className="deleteAction"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setAnchorEl(null);
                      props.handleDeleteModule(module);
                    }}
                  >
                    <img src={imageBasePath + 'delete_red.svg'} alt="Disable" />
                    <span className="supportText">Delete</span>
                  </MenuItem>
                ) : null}
              </Menu>
            </div>
          </div>
          <div className={styles.bottomBlk}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              className={styles.moduleActionRow}
            >
              {['user'].includes(role) ? (
                <>
                  {FeatureFlag.enabled('SHOW_RECURRING_CONTENT') ? (
                    <>
                      <Grid item lg={7} md={7} sm={12} xs={12}>
                        <div style={{ display: 'flex', alignContent: 'center', gap: '0.5rem' }}>
                          <img src={Contents} alt="content icon" />

                          <strong> {` ${module.content_count} `}</strong>

                          {module?.total_contents_schedule > 0 ? (
                            <>
                              <span
                                className={styles.contentCount + ' paragraph'}
                              >{`/ ${module?.total_contents_schedule} Recurring`}</span>
                              <img src={Recurring} alt="content icon" />
                            </>
                          ) : (
                            <span className={styles.contentCount + ' paragraph'}>{`Contents`}</span>
                          )}
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <Grid item lg={7} md={7} sm={12} xs={12}>
                      <p>
                        {module.completed_count ? (
                          <span
                            className={styles.contentCount + ' paragraph'}
                          >{`${module.completed_count}/${module.content_count} contents completed`}</span>
                        ) : (
                          <span
                            className={styles.contentCount + ' paragraph'}
                          >{`${module.content_count} content`}</span>
                        )}
                      </p>
                    </Grid>
                  )}
                  <Grid item lg={5} md={5} sm={12} xs={12}>
                    {module.completed_count ? (
                      <div className={styles.progressBlk}>
                        <ProgressBar
                          value={(module.completed_count / module.content_count) * 100}
                          max={100}
                          className={styles.stepProgress + ' stepProgress'}
                        />
                      </div>
                    ) : null}
                  </Grid>
                </>
              ) : (
                <>
                  {module.status == 0 ? (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <StatusLabel disabled />
                    </Grid>
                  ) : module.published_at ? (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <StatusLabel published />
                    </Grid>
                  ) : module.content_count ? (
                    <Grid item xs={12} sm={12} md={2} lg={2}></Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <p>
                        <span className={styles.contentCount + ' paragraph'}>0 Content added</span>
                        <CustomTooltipWhite
                          placement="top"
                          tooltipIcon="info_icon.svg"
                          title={
                            <div className="customTooltipContent">
                              <p className="heading6">What content to add ? </p>
                              <List className="listInline">
                                <ListItem className="supportText">
                                  Include what sales process is
                                </ListItem>
                                <ListItem className="supportText">
                                  What is followed in your company
                                </ListItem>
                                <ListItem className="supportText">Best practices in sales</ListItem>
                              </List>
                            </div>
                          }
                        />
                      </p>
                    </Grid>
                  )}
                  {module.status == 1 && module.content_count && !module.published_at ? (
                    <Grid item xs={12} sm={12} md={10} lg={10}>
                      <List className={styles.listInline + ' listInline'}>
                        <ListItem className="listInlineItem ">
                          <CustomButton
                            className={`${styles.addContentBtn} primaryBtn smallBtn ${
                              loader ? `disabled` : ''
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              // handlePublishModule()
                              setOpenConfirm(true);
                            }}
                          >{`Publish`}</CustomButton>
                        </ListItem>
                        <ListItem className="listInlineItem">
                          <CustomButton
                            className={styles.addContentBtn + ' secondaryBtn smallBtn'}
                            onClick={handleClickAddContent}
                          >
                            + Add content
                          </CustomButton>
                        </ListItem>
                      </List>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <List className={styles.listInline + ' listInline'}>
                        <ListItem className="listInlineItem">
                          <CustomButton
                            className={styles.addContentBtn + ' secondaryBtn smallBtn'}
                            onClick={handleClickAddContent}
                          >
                            + Add content
                          </CustomButton>
                        </ListItem>
                      </List>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </div>
        </div>
        {/* <Shimmer type="ModuleCard" /> */}
      </ReadOnlyWrapper>
      <ConfirmationDialog
        open={openConfirm}
        title={
          <>
            Are you sure you want to publish module{' '}
            <strong className="heading3">"{module.title}"</strong>?
          </>
        }
        description="This action can't be undone, but you still can add and update your content in this module"
        acceptText="Yes, Publish"
        rejectText="No, Cancel"
        image={imageBasePath + 'module_added_success.svg'}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleSubmit={() => {
          handlePublishModule();
          setOpenConfirm(false);
        }}
        loader={loader}
      />
    </>
  );
}
