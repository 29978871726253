import { Component } from 'react';
import { AdminModuleDetailContainer, UserModuleDetailContainer } from '../components';
import styles from '../../../assets/styles/training.module.scss';

export default class ModuleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props.role?.slug,
    };
  }
  render() {
    return (
      <div className={styles.trainingPage}>
        <section className={styles.moduleDetailSection}>
          {['primary_admin', 'admin', 'sales_lead'].includes(this.state.role) ? (
            <AdminModuleDetailContainer role={this.state.role} />
          ) : (
            <UserModuleDetailContainer role={this.state.role} />
          )}
        </section>
      </div>
    );
  }
}
