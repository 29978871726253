import React, { Component } from 'react';
import styles from '../../../assets/styles/onboarding.module.scss';
import { AdminOnboardingContainer, UserOnboardingContainer } from '../components';
// import { GlobalContext } from '../../../context/GlobalContext';

export default class Onboarding extends Component {
  // static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      role: props.role?.slug,
    };
  }
  render() {
    // const{state} = this.context;
    return (
      <div className={styles.onboardingPage}>
        {['primary_admin', 'admin'].includes(this.state.role) ? (
          <AdminOnboardingContainer />
        ) : (
          <UserOnboardingContainer />
        )}
      </div>
    );
  }
}
