import React, { useState, useEffect, useContext } from 'react';
import { Grid, List, ListItem, ClickAwayListener } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { withRouter, useLocation } from 'react-router-dom';
import { imageBasePath } from '../constants';
import { ProfileDropdown, InviteUserDialog } from './';
import { GlobalContext } from '../context/GlobalContext';
import { roleConfig } from './roleConfiguration';
import queryString from 'query-string';
import NotificationPopup from './NotificationPopup';
import { globalGetService } from '../utils/globalApiServices';
import { updateLocalSubscription } from 'features/payment/Payment.utils';
import { useGetSubscriptionDataQuery } from 'features/payment/Payment.queries';
import NotificationCount from './NotificationCount';

function Header(props) {
  const [userInfo, setUserInfo] = useState({});
  const [openInvitePop, setInvitePop] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);
  const [notification, setNotification] = useState(false);
  const [anchorEl, setAnchorEl] = useState([]);
  const [clickedAway, setClickedAway] = useState(false);
  const [notificationCnt, notificationCntSet] = useState(false);

  let location = useLocation();
  useEffect(() => {
    getNotificationCnt();
    getSettings();
    if (localStorage.getItem('userInfo')) {
      setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
    } else {
      // props.history.push('/register')
    }
  }, []);

  const querys = queryString.parse(location.search, { decode: false });
  useEffect(() => {
    if (querys.invite && querys.email) {
      setInvitePop(true);
    }
  }, [querys.invite, querys.email]);

  const handleNotification = (e) => {
    setAnchorEl(e.currentTarget);
    setNotification(!notification);
  };

  const handleClickOutside = (e) => {
    setNotification(false);
    setClickedAway(true);
  };

  const getNotificationCnt = () => {
    globalGetService(`/notifications/count/`).then((response) => {
      if (response.statusCode === 200) {
        notificationCntSet(response.data);
        dispatch({
          type: 'NOTOFICATION_COUNT',
          payload: response.data,
        });
      }
    });
  };

  const history = useHistory();

  const checkRecruitmentEnabled = (enabled) => {
    if (location.pathname?.includes('recruitment') && !enabled) {
      history.push('/');
    }
  };

  const getSettings = () => {
    globalGetService(`/settings/details/`)
      .then((response) => {
        if (response.statusCode === 200) {
          let userInfo = JSON.parse(localStorage.getItem('userInfo'));
          userInfo.selectedOrg = {
            ...userInfo.selectedOrg,
            ...response.data,
          };
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          checkRecruitmentEnabled(response.data.recruiting_module_enabled);

          updateLocalSubscription(response.data.subscription);
        }
      })
      .catch((e) => console.log(e));
  };

  const brandedLogo = JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg?.brandedLogo;

  // Retrieve subscription data
  useGetSubscriptionDataQuery();

  return (
    <>
      <header className="primaryHeader">
        <div className="headerWrapper">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="headerRow"
          >
            <Grid item xs={6} className="logo-container">
              <Link to="/" className="logo">
                <img src={brandedLogo || imageBasePath + 'logo.svg'} alt="Logo" />
              </Link>
            </Grid>
            <Grid item xs={6}>
              <ClickAwayListener onClickAway={(e) => handleClickOutside(e)}>
                <List className="listInline textRight">
                  {roleConfig[state.role?.id] ? (
                    roleConfig[state.role?.id].canInviteUser ? (
                      <ListItem className="listInlineItem hidden">
                        <a onClick={() => setInvitePop(true)}>
                          <img
                            className="activeImg"
                            src={imageBasePath + 'inviteuser_active.svg'}
                            alt="Invite user"
                          />
                          <img
                            className="defaultImg"
                            src={imageBasePath + 'inviteuser_default.svg'}
                            alt="Invite user"
                          />
                        </a>
                      </ListItem>
                    ) : null
                  ) : null}
                  {/* <ListItem className="listInlineItem">
                    <a>
                      <img
                        className='activeImg'
                        src={imageBasePath+'help_active.svg'}
                        alt='Help'
                      />
                      <img
                        className='defaultImg'
                        src={imageBasePath+'help_default.svg'}
                        alt='Help'
                      />
                    </a>
                  </ListItem> */}
                  <ListItem className="listInlineItem notification">
                    <a onClick={handleNotification}>
                      <img
                        className="activeImg"
                        src={imageBasePath + 'notification_active.svg'}
                        alt="Notification"
                      />
                      <img
                        className="defaultImg"
                        src={imageBasePath + 'notification_default.svg'}
                        alt="Notification"
                      />
                      <NotificationCount count={state.notificationCount} />
                      <NotificationPopup open={notification} />
                    </a>
                  </ListItem>
                  <ListItem className="listInlineItem">
                    <ProfileDropdown />
                  </ListItem>
                </List>
              </ClickAwayListener>
            </Grid>
          </Grid>
        </div>
      </header>
      <div className="emptyHeaderdiv"></div>
      <InviteUserDialog
        open={openInvitePop}
        handleClose={() => setInvitePop(false)}
        email={querys.email}
      />
    </>
  );
}

export default withRouter(Header);
