import { LinearProgress, CircularProgress } from '@material-ui/core';
import React from 'react';

const Loading = ({
  loading,
  circular,
  width = '100%',
  margin = '3rem 0',
  size = 32,
  style = {},
}) => {
  return !loading ? null : (
    <div style={{ display: 'flex', justifyContent: 'center', width, ...style }}>
      {circular ? (
        <CircularProgress size={size} style={{ margin, display: 'inline-block' }} />
      ) : (
        <LinearProgress style={{ marginTop: '1rem', width: '60%' }} />
      )}
    </div>
  );
};

export default Loading;
