import React, { useMemo, useState } from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import Organization from 'infrastructure/Organization';
import { InputSelect } from 'shared_ui_components';
import { useGetListUsersQuery } from 'features/roleplay/Roleplay.queries';
import User from 'infrastructure/User';

const RoleplaySelectAssign = () => {
  // Get users information
  const { data: usersList = [] } = useGetListUsersQuery();

  const isRep = Organization.isRep();
  const formDataAssignRoleplay = useRoleplayStore((state) => state?.formDataAssignRoleplay);
  const setFormDataAssignRoleplay = useRoleplayStore((state) => state?.setFormDataAssignRoleplay);
  const [valueList, setValueList] = useState([]);

  const options = useMemo(() => {
    if (!usersList?.length > 0) return [];
    return usersList.map((user) => {
      return {
        label: User.getUserName(user?.user),
        value: user.user.uuid,
      };
    });
  }, [usersList]);

  const handleChange = (values) => {
    delete formDataAssignRoleplay.users;
    let newValue = values;
    if (!isRep)
      if (values.some((item) => item.value === -1)) {
        newValue = usersList.map((user) => {
          return {
            label: User.getUserName(user?.user),
            value: user.user.uuid,
          };
        });
        setValueList(newValue);
        setFormDataAssignRoleplay({ users: newValue, ...formDataAssignRoleplay });
        return;
      }
    setValueList(newValue);
    setFormDataAssignRoleplay({ users: newValue, ...formDataAssignRoleplay });
  };

  return (
    <>
      {isRep ? (
        <InputSelect
          label={`${isRep ? 'Manager' : 'Sales Reps'}`}
          placeholder={`${isRep ? 'Select Manager' : 'Select Sales Reps'}`}
          onChange={(values) => handleChange(values)}
          options={options}
          closeMenuOnSelect={true}
          noOptionsMessage={() => 'No matches found'}
          // value={valueList}
        />
      ) : (
        <InputSelect
          isMulti
          closeMenuOnSelect={true}
          options={[{ label: 'All Users', value: -1 }, ...options]}
          label={`${isRep ? 'Manager' : 'Sales Reps'}`}
          placeholder={`${isRep ? 'Select Manager' : 'Select Sales Reps'}`}
          onChange={(values) => handleChange(values)}
          noOptionsMessage={() => 'No matches found'}
          isClearable
          value={valueList}
        />
      )}
    </>
  );
};

export default RoleplaySelectAssign;
